import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import StoriesOverview from '../components/StoriesOverview';
import StoriesOverviewYear from '../components/StoriesOverviewYear';
import ClassifiableStoriesOverview from '../components/ClassifiableStoriesOverview';
import TrendsOverview from '../components/TrendsOverview';
import ExtendedHashtags from '../components/ExtendedHashtags';
import ExtendedInfluencers from '../components/ExtendedInfluencers';
import About from '../components/About';
import Ranking from '../components/Ranking';
import Privacy from '../components/Privacy';

class HealthBuzzBody extends Component {

    render() {
        const Page404 = ({ location }) => (
            <Redirect to='/' />
        );

        return <Switch>
            <Route exact path='/' render={(props) =>
                <StoriesOverview {...props}
                    language={this.props.language}
                    window={this.props.window} />} />
            <Route path='/articles/2021' render={(props) =>
                <StoriesOverviewYear {...props} />} />
            <Route path='/articles' render={(props) =>
                <StoriesOverview {...props}
                    language={this.props.language}
                    window={this.props.window} />} />
            <Route path='/scoreme' render={(props) =>
                <ClassifiableStoriesOverview {...props}
                    language={this.props.language}
                    window={this.props.window} />} />
            <Route path='/influencersplus' render={(props) =>
                <ExtendedInfluencers {...props}
                    language={this.props.language}
                    window={this.props.window} />} />
            <Route path='/influencers' render={(props) =>
                <TrendsOverview {...props}
                    language={this.props.language}
                    window={this.props.window}
                    menuChoice={this.props.menuChoice} />} />
            <Route path='/hashtags' render={(props) =>
                <ExtendedHashtags {...props}
                    window={this.props.window} />} />
            <Route path='/about' render={(props) =>
                <About {...props} />} />
            <Route path='/rankings' render={(props) =>
                <Ranking {...props} />} />
            <Route path='/privacy' render={(props) =>
                <Privacy {...props} />} />
            <Route component={Page404} />
        </Switch>;
    }
}

export default HealthBuzzBody;
