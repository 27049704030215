import React, { Component, Fragment } from 'react';
import BaseMenu from '../components/BaseMenu.jsx';
import FilterMenu from '../components/FilterMenu.jsx';

class HealthBuzzHeader extends Component {

    state = {
        menuChoice: 'Articles'
    };

    setMenuChoice = (choice) => {
        this.setState({
          menuChoice: choice
        });
      }

      componentDidUpdate(prevProps, prevState) {
        if (prevProps.tweetMenuChoice !== this.props.tweetMenuChoice) {
            this.setState({
                menuChoice: this.props.tweetMenuChoice
            });
        }
    }

    render() {
        return <Fragment>
            <BaseMenu menuChoice={this.setMenuChoice} sideBarClick={this.props.sideBarClick}/>
            <FilterMenu filter={this.props.filter} menuChoice={this.state.menuChoice}/>
        </Fragment>;
    }
}

export default HealthBuzzHeader;