import React, { Component, Fragment } from 'react';
import { Sidebar, Icon, Menu, Header, Button, Grid, Message, Container, Dimmer, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import HealthBuzzHeader from './components/HealthBuzzHeader';
import HealthBuzzBody from './components/HealthBuzzBody';
import * as BuzzConstants from './components/BuzzConstants.jsx';
import ScrollUpButton from "react-scroll-up-button";
import { Offline, Online } from "react-detect-offline";
import { withTranslation } from 'react-i18next';

import i18n from "./i18n";

import './styles/App.css';

class App extends Component {


  constructor(props) {
    super(props);

    this.state = {
      window: 'Week',
      language: 'all',
      sideBarVisible: false,
      showModal: false,
      response: '',
      serverUp: false,
      maintenance: false
    };

  }

  checkServerStatus() {
    fetch(BuzzConstants.NEWS_WEEK)
      .then(result => result.json())
      .then(testResponse => this.setState({ response: testResponse }));
  }

  isServerUpAndRunning = () => {
    return true;
  }

  componentDidMount() {
    this.checkServerStatus();
    i18n.changeLanguage('nl');
  }

  handleSidebarHide = () => this.setState({ sideBarVisible: false })

  handleSidebarClick = () => {
    this.setState({ sideBarVisible: true })
  }

  setMenuChoice = (choice) => {
    this.setState({
      tweetMenuChoice: choice
    });
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  setFilter = (window, language, region) => {
    console.log("Region: " + region);
    this.setState({
      window: window,
      language: language,
      region: region
    });
  }

  getMainPage = () => {
    const { t } = this.props;
    const { showModal } = this.state

    return <div className="App">
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation='overlay'
          icon='labeled'
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={this.state.sideBarVisible}
          width='thin'
          direction='right'
        >
          <Menu.Item as={Link} to='/about' onClick={this.handleSidebarHide}>
            <Icon name='info' />
            {t("About")}
          </Menu.Item>
          <Menu.Item as={Link} to='/rankings' onClick={this.handleSidebarHide}>
            <Icon name='list ol' />
            {t("Rankings")}
          </Menu.Item>
          <Menu.Item as={Link} to='/privacy' onClick={this.handleSidebarHide}>
            <Icon name='privacy' />
            Privacy
        </Menu.Item>
        </Sidebar>
        <Sidebar.Pusher dimmed={this.state.sideBarVisible}>
          <HealthBuzzHeader filter={this.setFilter} sideBarClick={this.handleSidebarClick} tweetMenuChoice={this.state.tweetMenuChoice} />
          <div className="App-intro">
            <HealthBuzzBody window={this.state.window} language={this.state.language} region={this.state.region} menuChoice={this.setMenuChoice} />
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
      <ScrollUpButton ContainerClassName="AnyClassForContainer"
        TransitionClassName="AnyClassForTransition">
        <Button circular icon='angle double up' size='tiny' />
      </ScrollUpButton>
    </div>;
  }

  getHealthBuzz = () => {
    return this.getMainPage();
  }


  getOfflinePage = () => {
    return <div className="App-intro">
      <Container text>
        <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: 800 }}>
            <Header as='h2' color='teal' textAlign='center'>
              Oops...someone pulled the plug, you seem to be offline
                </Header>
            <Message color='teal' >
              <Icon name='star' />
              Once you're back online we've got some new Belgian health buzz for you!
                </Message>
          </Grid.Column>
        </Grid>
      </Container>
    </div>;
  }

  getMaintenancePage = () => {
    return <div className="App-intro">
      <Container text>
        <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: 600 }}>
            <Header as='h2' color='teal' textAlign='center'>
              We're down for scheduled maintenance now
                </Header>
            <Message color='teal' >
              <Icon name='star' />
              We'll be serving you with the latest Belgian health buzz soon again!
                </Message>
          </Grid.Column>
        </Grid>
      </Container>
    </div>;
  }

  getLoadingPage = () => {
    return <div className="App-intro">
      <Dimmer active>
        <Loader inverted />
      </Dimmer>
    </div>;
  }

  getOnlinePage = () => {
    if (this.isServerUpAndRunning()) {
      return <React.Fragment>{this.getHealthBuzz()}</React.Fragment>;
    } else if (this.state.maintenance) {
      return <React.Fragment>{this.getMaintenancePage()}</React.Fragment>;
    } else return <React.Fragment>{this.getLoadingPage()}</React.Fragment>;
  }

  render() {

    return <Fragment>
      <Offline>{this.getOfflinePage()}</Offline>
      <Online>{this.getOnlinePage()}</Online>
    </Fragment>

  }

}

export default withTranslation()(App);