import React, { Component } from 'react';
import { Segment, Grid, Header, List } from 'semantic-ui-react';

class Privacy extends Component {

    render() {
        return (
            <React.Fragment>
                <Segment style={{ padding: '2em 0em' }} vertical>
                    <Grid container stackable verticalAlign='top'>
                        <Grid.Row stretched>
                            <Grid.Column width={8}>
                                <Segment padded='very' raised>
                                    <Header as='h4' style={{ fontSize: '1.6em' }}>
                                        Privacy Policy for PoolstokBuzz
                                </Header>
                                    <p style={{ fontSize: '1.1em' }}>
                                        This is the Privacy Policy for PoolstokBuzz, accessible from <a href='https://www.poolstok.buzz/privacy'>https://www.poolstok.buzz/privacy</a></p>
                                    <p style={{ fontSize: '1.1em' }}>
                                        PoolstokBuzz is an initiative of Poolstok and developed and hosted by Health Endeavour. This privacy policy will explain how our organization uses the personal data we collect from you when you use our website.</p>
                                    <Header as='h6' style={{ fontSize: '1.2em' }}>
                                        What Data Do We Collect?
                                    </Header>
                                    <p style={{ fontSize: '1.1em' }}>
                                        We collects the following data:</p>
                                    <List>
                                        <List.Item>
                                            <List.Icon name='edit' color='teal' />
                                            <List.Content>
                                                <List.Header>Personal Identification when contacting us</List.Header>
                                                <List.Description>When contacting us through our contact page we collect your name and email. You have as well the option to opt-in for receiving updates through email.</List.Description>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Icon name='check circle' color='teal' />
                                            <List.Content>
                                                <List.Header>Preferences through cookies</List.Header>
                                                <List.Description>Please see the PoolstokBuzz cookie policy for detailed information about our cookie usage.</List.Description>
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                    <Header as='h6' style={{ fontSize: '1.2em' }}>
                                        How Do We Collect Your Data?
                                    </Header>
                                    <p style={{ fontSize: '1.1em' }}>
                                        You directly provide us with most of the data we collect. We collect data and process data when you:</p>
                                    <List>
                                        <List.Item>
                                            <List.Icon name='chrome' color='teal' />
                                            <List.Content>
                                                <List.Header>Use or view our website via your browser’s cookies.</List.Header>
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                    <Header as='h6' style={{ fontSize: '1.2em' }}>
                                        How Will We Use Your Data?
                                    </Header>
                                    <p style={{ fontSize: '1.1em' }}>
                                        We use your data solely to optimize your PoolstokBuzz experience.</p>
                                    <p style={{ fontSize: '1.1em' }}>
                                        We treat your data confidentially and don't share your data with any other organisations.</p>
                                    <Header as='h6' style={{ fontSize: '1.2em' }}>
                                        How Do We Store Your Data?
                                    </Header>
                                    <p style={{ fontSize: '1.1em' }}>
                                        All your data is securely stored in a Google data center within the EU. </p>
                                    <p style={{ fontSize: '1.1em' }}>
                                        We will keep your data records for 10 years. Once this time period has expired, we will delete your data by removing all of your data from our data centers.</p>
                                    <Header>What Are Your Data Protection Rights?</Header>
                                    <p style={{ fontSize: '1.1em' }}>Health Endeavour would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
                                    <List bulleted>
                                        <List.Item>The right to access – You have the right to request Health Endeavour for copies of your personal data. We may charge you a small fee for this service.</List.Item>
                                        <List.Item>The right to rectification – You have the right to request that Health Endeavour correct any information you believe is inaccurate. You also have the right to request Health Endeavour to complete the information you believe is incomplete.</List.Item>
                                        <List.Item>The right to erasure – You have the right to request that Health Endeavour erase your personal data, under certain conditions.</List.Item>
                                        <List.Item>The right to restrict processing – You have the right to request that Health Endeavour restrict the processing of your personal data, under certain conditions.</List.Item>
                                        <List.Item>The right to object to processing – You have the right to object to Health Endeavour’s processing of your personal data, under certain conditions.</List.Item>
                                        <List.Item>The right to data portability – You have the right to request that Health Endeavour transfer the data that we have collected to another organization, or directly to you, under certain conditions.</List.Item>
                                    </List>
                                    <p style={{ fontSize: '1.1em' }}>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at <a mailto="hello@poolstok.buzz">hello@poolstok.buzz</a>.</p>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Segment padded='very' raised>
                                    <Header as='h4' style={{ fontSize: '1.6em' }}>
                                        Cookie Policy for PoolstokBuzz
                                    </Header>
                                    <Header as='h6' style={{ fontSize: '1.2em' }}>
                                        What Are Cookies?
                                    </Header>
                                    <p style={{ fontSize: '1.1em' }}>
                                        As is common practice with almost all professional websites this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the sites functionality.
                                    </p>
                                    <p style={{ fontSize: '1.1em' }}>
                                        For more general information on cookies please see <a href="https://en.wikipedia.org/wiki/HTTP_cookie">here</a>.
                                    </p>
                                    <Header as='h6' style={{ fontSize: '1.2em' }}>
                                        How We Use Cookies
                                    </Header>
                                    <p style={{ fontSize: '1.1em' }}>
                                        We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.
                                    </p>
                                    <Header as='h6' style={{ fontSize: '1.2em' }}>
                                        Disabling Cookies
                                    </Header>
                                    <p style={{ fontSize: '1.1em' }}>
                                        You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of the this site. Therefore it is recommended that you do not disable cookies.
                                    </p>
                                    <Header as='h6' style={{ fontSize: '1.2em' }}>
                                        The Cookies We Set
                                    </Header>
                                    <p style={{ fontSize: '1.1em' }}>
                                        <List>
                                            <List.Item>
                                                <List.Icon name='edit' color='teal' />
                                                <List.Content>
                                                    <List.Header>Forms related cookies</List.Header>
                                                    <List.Description>When you submit data through a form cookies may be set to remember your user details for future correspondence.</List.Description>
                                                </List.Content>
                                            </List.Item>
                                            <List.Item>
                                                <List.Icon name='check square' color='teal' />
                                                <List.Content>
                                                    <List.Header>Site preference cookies</List.Header>
                                                    <List.Description>In order to provide you with a great experience on this site we provide the functionality to set your preferences for how this site runs when you use it. In order to remember your preferences such as your preferred language we need to set cookies so that this information can be called whenever you interact with a page is affected by your preferences.</List.Description>
                                                </List.Content>
                                            </List.Item>
                                        </List>
                                    </p>
                                    <Header as='h6' style={{ fontSize: '1.2em' }}>
                                        Third Party Cookies
                                    </Header>
                                    <p style={{ fontSize: '1.1em' }}>
                                        In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.
                                    </p>
                                    <List>
                                        <List.Item>
                                            <List.Icon name='google' color='teal' />
                                            <List.Content>
                                                <List.Header>Google Analytics</List.Header>
                                                <List.Description>This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the web for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content. For more information on Google Analytics cookies, see the official <a href="">Google Analytics page</a>.</List.Description>
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                    <Header as='h6' style={{ fontSize: '1.2em' }}>
                                        More Information
                                    </Header>
                                    <p style={{ fontSize: '1.1em' }}>
                                        Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our site. This Cookies Policy was created with the help of the Cookies Policy Template Generator and the WebTerms Generator.
                                    </p>
                                    <p style={{ fontSize: '1.1em' }}>
                                        However if you are still looking for more information then you can contact us at <a mailto="hello@poolstok.buzz">hello@poolstok.buzz</a>.
                                    </p>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </React.Fragment>);
    }
}

export default Privacy;