/* Languages */
export const LANGUAGES_EN = [
    { key: 'en', value: 'en', flag: 'gb', text: 'English' },
    { key: 'nl', value: 'nl', flag: 'nl', text: 'Dutch' },
    { key: 'fr', value: 'fr', flag: 'fr', text: 'French' },
    { key: 'al', value: 'all', icon: 'world', text: 'All Languages' }];

export const LANGUAGES_NL = [{ key: 'nl', value: 'nl', flag: 'nl', text: 'Nederlands' },
{ key: 'fr', value: 'fr', flag: 'fr', text: 'Frans' },
{ key: 'en', value: 'en', flag: 'gb', text: 'Engels' },
{ key: 'al', value: 'all', icon: 'world', text: 'Alle Talen' }];

export const LANGUAGES_FR = [{ key: 'fr', value: 'fr', flag: 'fr', text: 'Français' },
{ key: 'nl', value: 'nl', flag: 'nl', text: 'Néerlandais' },
{ key: 'en', value: 'en', flag: 'gb', text: 'Anglais' },
{ key: 'al', value: 'all', icon: 'world', text: 'Toutes Langues' }];

export const COUNTRY_OPTIONS = [{ key: 'nl', value: 'nl', flag: 'nl', text: 'Nederlands' },
{ key: 'fr', value: 'fr', flag: 'fr', text: 'Français' },
{ key: 'en', value: 'en', flag: 'gb', text: 'English' }];

/* Hashtag URLs */
export const HASHTAGS_DAY = `https://api.poolstok.buzz/restapi/summary/day`;
export const HASHTAGS_WEEK = `https://api.poolstok.buzz/restapi/summary/week`;
export const HASHTAGS_MONTH = `https://api.poolstok.buzz/restapi/summary/month`;
export const HASHTAGS_QUARTER = `https://api.poolstok.buzz/restapi/summary/quarter`;
export const HASHTAGS_YEAR = `https://api.poolstok.buzz/restapi/summary/year`;

/* Influencer URLs */
export const INFLUENCERS_WEEK = `https://api.poolstok.buzz/restapi/ranking/week`;
export const INFLUENCERS_MONTH = `https://api.poolstok.buzz/restapi/ranking/month`;
export const INFLUENCERS_QUARTER = `https://api.poolstok.buzz/restapi/ranking/quarter`;
export const INFLUENCERS_YEAR = `https://api.poolstok.buzz/restapi/ranking/year`;
export const INFLUENCERS_YEAR_EXT = `https://api.poolstok.buzz/restapi/ranking/year/extended`;
export const INFLUENCERS_2019 = `https://api.poolstok.buzz/restapi/ranking/2019`;
export const INFLUENCERS_2019_PERSONS = `https://api.poolstok.buzz/restapi/ranking/2019?type=PERSON`;
export const INFLUENCERS_2019_ORGS = `https://api.poolstok.buzz/restapi/ranking/2019?type=ORGANISATION`;
export const INFLUENCERS_2020 = `https://api.poolstok.buzz/restapi/ranking/2020`;
export const INFLUENCERS_2020_PERSONS = `https://api.poolstok.buzz/restapi/ranking/2020?type=PERSON`;
export const INFLUENCERS_2020_ORGS = `https://api.poolstok.buzz/restapi/ranking/2020?type=ORGANISATION`;
export const INFLUENCERS_BASE = `https://api.poolstok.buzz/restapi/ranking`;

/* News URLs */
export const NEWS_DAY = 'https://api.poolstok.buzz/restapi/news/24';
export const NEWS_TWO_DAYS = 'https://api.poolstok.buzz/restapi/news/48';
export const NEWS_WEEK = 'https://api.poolstok.buzz/restapi/news/week';
export const NEWS_MONTH = 'https://api.poolstok.buzz/restapi/news/month';
export const NEWS_YEAR = 'https://api.poolstok.buzz/restapi/news/year';
export const NEWS_YEAR_PREVIOUS = 'https://api.poolstok.buzz/restapi/news/year/previous';
export const NEWS_LATEST = 'https://api.poolstok.buzz/restapi/news/latest';
export const NEWS_QUERY = 'https://api.poolstok.buzz/restapi/news?url=';
export const NEWS_TO_BE_SCORED = 'https://api.poolstok.buzz/restapi/ucnewsv2/week';
export const SCORED_NEWS = 'https://api.poolstok.buzz/restapi/scorednews/week';

/* News URLs - English */
export const NEWS_DAY_EN = 'https://api.poolstok.buzz/restapi/news/24?lang=en';
export const NEWS_TWO_DAYS_EN = 'https://api.poolstok.buzz/restapi/news/48?lang=en';
export const NEWS_WEEK_EN = 'https://api.poolstok.buzz/restapi/news/week?lang=en';
export const NEWS_MONTH_EN = 'https://api.poolstok.buzz/restapi/news/month?lang=en';

/* News URLs - Dutch */
export const NEWS_DAY_NL = 'https://api.poolstok.buzz/restapi/news/24?lang=nl';
export const NEWS_TWO_DAYS_NL = 'https://api.poolstok.buzz/restapi/news/48?lang=nl';
export const NEWS_WEEK_NL = 'https://api.poolstok.buzz/restapi/news/week?lang=nl';
export const NEWS_MONTH_NL = 'https://api.poolstok.buzz/restapi/news/month?lang=nl';
export const NEWS_YEAR_NL = 'https://api.poolstok.buzz/restapi/news/yearinreview?lang=nl';
export const NEWS_YEAR_FR = 'https://api.poolstok.buzz/restapi/news/yearinreview?lang=fr';
export const NEWS_YEAR_EN = 'https://api.poolstok.buzz/restapi/news/yearinreview?lang=en';

/* News URLs - French */
export const NEWS_DAY_FR = 'https://api.poolstok.buzz/restapi/news/24?lang=fr';
export const NEWS_TWO_DAYS_FR = 'https://api.poolstok.buzz/restapi/news/48?lang=fr';
export const NEWS_WEEK_FR = 'https://api.poolstok.buzz/restapi/news/week?lang=fr';
export const NEWS_MONTH_FR = 'https://api.poolstok.buzz/restapi/news/month?lang=fr';

/* Digital Health News URLs */
export const NEWS_DAY_DH = 'https://api.digital.poolstok.buzz/restapi/news/24';
export const NEWS_WEEK_DH = 'https://api.digital.poolstok.buzz/restapi/news/week';
export const NEWS_DAY_EN_DH = 'https://api.digital.poolstok.buzz/restapi/news/24?lang=en';
export const NEWS_WEEK_EN_DH = 'https://api.digital.poolstok.buzz/restapi/news/week?lang=en';
export const NEWS_DAY_NL_DH = 'https://api.digital.poolstok.buzz/restapi/news/24?lang=nl';
export const NEWS_WEEK_NL_DH = 'https://api.digital.poolstok.buzz/restapi/news/week?lang=nl';
export const NEWS_DAY_FR_DH = 'https://api.digital.poolstok.buzz/restapi/news/24?lang=fr';
export const NEWS_WEEK_FR_DH = 'https://api.digital.poolstok.buzz/restapi/news/week?lang=fr';

/* Tweets URLs */
export const TWEETS_DAY = 'https://api.poolstok.buzz/restapi/tweets/24';
export const TWEETS_TWO_DAYS = 'https://api.poolstok.buzz/restapi/tweets/48';
export const TWEETS_WEEK = 'https://api.poolstok.buzz/restapi/tweets/week';

/* Tweets URLs - English */
export const TWEETS_DAY_EN = 'https://api.poolstok.buzz/restapi/tweets/24?lang=en';
export const TWEETS_TWO_DAYS_EN = 'https://api.poolstok.buzz/restapi/tweets/48?lang=en';
export const TWEETS_WEEK_EN = 'https://api.poolstok.buzz/restapi/tweets/week?lang=en';

/* News URLs - Dutch */
export const TWEETS_DAY_NL = 'https://api.poolstok.buzz/restapi/tweets/24?lang=nl';
export const TWEETS_TWO_DAYS_NL = 'https://api.poolstok.buzz/restapi/tweets/48?lang=nl';
export const TWEETS_WEEK_NL = 'https://api.poolstok.buzz/restapi/tweets/week?lang=nl';

/* News URLs - French */
export const TWEETS_DAY_FR = 'https://api.poolstok.buzz/restapi/tweets/24?lang=fr';
export const TWEETS_TWO_DAYS_FR = 'https://api.poolstok.buzz/restapi/tweets/48?lang=fr';
export const TWEETS_WEEK_FR = 'https://api.poolstok.buzz/restapi/tweets/week?lang=fr';

/* Event URLs */
export const EVENTS = 'https://api.poolstok.buzz/restapi/events';
export const EVENTREGISTRATION = 'https://api.poolstok.buzz/restapi/events';
export const EVENTREGISTRATIONREQUEST = 'https://api.poolstok.buzz/restapi/eventregistrationcode';

/* Status URLs */
export const STATUS = 'https://api.poolstok.buzz/restapi/actuator/health';

/* Mailing */
export const MAILING = 'https://api.poolstok.buzz/restapi/mailbox';
export const SIGNUP = 'https://api.poolstok.buzz/restapi/signup';

/* Live feed */
export const LIVEFEED = 'https://api.poolstok.buzz/restapi/livefeed';
export const LIVEFEED_ACTIVE = 'https://api.poolstok.buzz/restapi/livefeed/active';

/* News classification */
export const NEWS_CLASSIFICATION = 'https://api.poolstok.buzz/restapi/news/classification';
export const NEWS = 'https://api.poolstok.buzz/restapi/news';