import React, { Component, Fragment } from 'react';
import { Menu, Icon, Header, Popup, Dropdown, Button, Responsive, Image, Breadcrumb } from 'semantic-ui-react';
import { Switch, Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as BuzzConstants from '../components/BuzzConstants.jsx';

import helogo from '../images/he-logo2.png';
import i18n from '../i18n.js';

class FilterMenu extends Component {

    state = {
        language: 'all',
        window: 'Week',
        region: 'Worldwide',
        dayButtonColor: 'default',
        weekButtonColor: 'teal',
        hashtagWeekButtonColor: 'default',
        influencerWeekButtonColor: 'teal',
        monthButtonColor: 'default',
        quarterButtonColor: 'default',
        yearButtonColor: 'default',
        belgiumButtonColor: 'default',
        worldwideButtonColor: 'teal'

    };

    filter = () => {
        this.props.filter(this.state.window, this.state.language, this.state.region);
    }

    setDayWindow = () => {
        this.setState({
            window: 'Day',
            dayButtonColor: 'teal',
            hashtagWeekButtonColor: 'default',
            influencerWeekButtonColor: 'default',
            weekButtonColor: 'default',
            monthButtonColor: 'default',
            quarterButtonColor: 'default',
            yearButtonColor: 'default',
        });
    }

    setWeekWindow = () => {
        this.setState({
            window: 'Week',
            hashtagWeekButtonColor: 'teal',
            influencerWeekButtonColor: 'teal',
            weekButtonColor: 'teal',
            dayButtonColor: 'default',
            monthButtonColor: 'default',
            quarterButtonColor: 'default',
            yearButtonColor: 'default',
        });
    }

    handleWindowClick = (e, { name }) => {
        this.setState({ window: name });
        if (name === 'Day') {
            if (this.state.dayButtonColor === 'default') {
                this.setDayWindow();
            }
        } else if (name === 'Week') {
            if (this.state.hashtagWeekButtonColor === 'default') {
                this.setWeekWindow();
            }
        } else if (name === 'Month') {
            if (this.state.monthButtonColor === 'default') {
                this.setState({
                    monthButtonColor: 'teal',
                    dayButtonColor: 'default',
                    hashtagWeekButtonColor: 'default',
                    influencerWeekButtonColor: 'default',
                    weekButtonColor: 'default',
                    quarterButtonColor: 'default',
                    yearButtonColor: 'default',
                });
            }
        } else if (name === 'Quarter') {
            if (this.state.quarterButtonColor === 'default') {
                this.setState({
                    quarterButtonColor: 'teal',
                    dayButtonColor: 'default',
                    hashtagWeekButtonColor: 'default',
                    influencerWeekButtonColor: 'default',
                    weekButtonColor: 'default',
                    monthButtonColor: 'default',
                    yearButtonColor: 'default',
                });
            }
        } else if (name === 'Year') {
            if (this.state.yearButtonColor === 'default') {
                this.setState({
                    quarterButtonColor: 'default',
                    dayButtonColor: 'default',
                    hashtagWeekButtonColor: 'default',
                    influencerWeekButtonColor: 'default',
                    weekButtonColor: 'default',
                    monthButtonColor: 'default',
                    yearButtonColor: 'teal',
                });
            }
        }
    }

    handleRegionClick = (e, { name }) => {
        this.setState({ region: name });
        if (name === 'Belgium') {
            if (this.state.belgiumButtonColor === 'default') {
                this.setState({
                    belgiumButtonColor: 'teal',
                    worldwideButtonColor: 'default'
                });
            }
        } else if (name === 'Worldwide') {
            if (this.state.worldwideButtonColor === 'default') {
                this.setState({
                    belgiumButtonColor: 'default',
                    worldwideButtonColor: 'teal'
                });
            }
        }
    }

    handleLanguageChange = (e, { value }) => {
        this.setState({ language: value });
    }

    getLanguages = () => {
        if (i18n.language === 'nl') {
            return BuzzConstants.LANGUAGES_NL;
        } else if (i18n.language === 'fr') {
            return BuzzConstants.LANGUAGES_FR;
        } else
            return BuzzConstants.LANGUAGES_EN;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.window !== this.state.window) {
            this.filter();
        }
        if (prevState.language !== this.state.language) {
            this.filter();
        }
        if (prevState.region !== this.state.region) {
            this.filter();
        }
        if (this.props.menuChoice !== prevProps.menuChoice) {
            if (this.props.menuChoice === 'Influencers') {
                this.setWeekWindow();
            }
        }
    }

    getSponsorMenuItem = () => {
        return <Menu.Item href="https://www.poolstok.be" target="_blank">
            <div className='PoweredHeader'>Powered By Science</div>
        </Menu.Item>
    }

    ArticlesFilterMenu = () => {
        const { t } = this.props;
        return <Menu size='small' stackable borderless>
            <Menu.Menu position='left'>
                <Responsive maxWidth={768}>
                    {this.getSponsorMenuItem()}
                </Responsive>
                <Responsive minWidth={1025}>
                    <Menu.Item>
                    </Menu.Item>
                </Responsive>
                {/*
                <Menu.Item>
                    <Popup size='small' trigger={<Icon name='calendar' size='large' color='teal' />} content={t('Select a time-frame')} />
                    <Button name='Week' onClick={this.handleWindowClick} basic color={this.state.weekButtonColor}>{t("Past Week")}</Button>&nbsp;
                    <Button name='Month' onClick={this.handleWindowClick} basic color={this.state.monthButtonColor}>{t("Past Month")}</Button>
                </Menu.Item>
    */}
            </Menu.Menu>
            <Responsive minWidth={769}>
                <Menu.Menu position='right'>
                    <Menu.Item href='https://twitter.com/Poolstok_' target='_blank'>
                        <Icon name='twitter' color='teal' size='large' />
                    </Menu.Item>
                    {this.getSponsorMenuItem()}
                </Menu.Menu>
            </Responsive>
        </Menu>;
    }

    Articles2021FilterMenu = () => {
        const { t } = this.props;
        return <Menu size='small' stackable borderless>
            <Menu.Menu position='left'>
                <Responsive maxWidth={768}>
                    {this.getSponsorMenuItem()}
                </Responsive>
                <Responsive minWidth={1025}>
                    <Menu.Item>
                    </Menu.Item>
                </Responsive>
                <Menu.Item>
                    <Header color='teal' size='large'>Meest gelezen in 2021</Header>
                </Menu.Item>
            </Menu.Menu>
            <Responsive minWidth={769}>
                <Menu.Menu position='right'>
                    <Menu.Item href='https://twitter.com/Poolstok_' target='_blank'>
                        <Icon name='twitter' color='teal' size='large' />
                    </Menu.Item>
                    {this.getSponsorMenuItem()}
                </Menu.Menu>
            </Responsive>
        </Menu>;
    }

    TopicFilterMenu = () => {
        const { t } = this.props;
        return <Menu size='small' stackable borderless>
            <Menu.Menu position='left'>
                <Responsive maxWidth={768}>
                    {this.getSponsorMenuItem()}
                </Responsive>
                <Menu.Item>
                    <Icon name='globe' size='large' color='teal' />
                    <Button name='Worldwide' onClick={this.handleRegionClick} basic color={this.state.worldwideButtonColor}>{t("Buzzing Worldwide")}</Button>
                    <Button name='Belgium' onClick={this.handleRegionClick} basic color={this.state.belgiumButtonColor}>{t("Buzzing in Belgium")}</Button>&nbsp;
                </Menu.Item>
            </Menu.Menu>
            <Responsive minWidth={769}>
                <Menu.Menu position='right'>
                    {this.getSponsorMenuItem()}
                </Menu.Menu>
            </Responsive>
        </Menu>;
    }

    TrendsFilterMenu = () => {
        const { t } = this.props;
        return <Menu size='small' stackable borderless>
            <Menu.Menu position='left'>
                <Responsive maxWidth={768}>
                    {this.getSponsorMenuItem()}
                </Responsive>
                <Responsive minWidth={1025}>
                    <Menu.Item>
                    </Menu.Item>
                </Responsive>
                <Menu.Item>

                </Menu.Item>
            </Menu.Menu>
            <Responsive minWidth={769}>
                <Menu.Menu position='right'>
                    {this.getSponsorMenuItem()}
                </Menu.Menu>
            </Responsive>
        </Menu>;
    }

    CalendarFilterMenu = () => {
        const { t } = this.props;
        return <Menu size='small' stackable borderless>
            <Responsive minWidth={769}>
                <Menu.Menu position='left'>
                    <Menu.Item>
                        <Button content={t("Register Your Event")} color='teal' as={Link} to='/eventregistration' icon='calendar check' labelPosition='left' basic />
                    </Menu.Item>
                </Menu.Menu>
            </Responsive>
            <Menu.Menu position='right'>
                <Responsive maxWidth={768}>
                    <Menu.Item href='https://www.healthendeavour.agency/' target='_blank'>
                        <div className='PoweredHeader'>POWERED BY</div>
                        <Image fluid src={helogo} size='small' />
                    </Menu.Item>
                    <Menu.Menu position='left'>
                        <Menu.Item>
                            <Button content={t("Register Your Event")} color='teal' as={Link} to='/eventregistration' icon='calendar check' labelPosition='left' basic />
                        </Menu.Item>
                    </Menu.Menu>
                </Responsive>
                <Responsive minWidth={769}>
                    {this.getSponsorMenuItem()}
                </Responsive>
            </Menu.Menu>
        </Menu>;
    }

    EventRegistrationFilterMenu = () => {
        const { t } = this.props;
        return <Menu size='small' stackable borderless>
            <Responsive minWidth={769}>
                <Menu.Menu position='left'>
                    <Menu.Item>
                        <Button content={t("Show Events")} color='teal' as={Link} to='/calendar' icon='calendar alternate' labelPosition='left' basic />
                    </Menu.Item>
                </Menu.Menu>
            </Responsive>
            <Menu.Menu position='right'>
                <Responsive maxWidth={768}>
                    {this.getSponsorMenuItem()}
                    <Menu.Menu position='left'>
                        <Menu.Item>
                            <Button content={t("Show Events")} color='teal' as={Link} to='/calendar' icon='calendar alternate' labelPosition='left' basic />
                        </Menu.Item>
                    </Menu.Menu>
                </Responsive>
                <Responsive minWidth={769}>
                    {this.getSponsorMenuItem()}
                </Responsive>
            </Menu.Menu>
        </Menu>;
    }

    HashTagFilterMenu = () => {
        const { t } = this.props;
        return <Fragment>
            <Responsive minWidth={1025}>
                <Menu size='small' stackable borderless>
                    <Menu.Menu position='left'>
                        <Menu.Item></Menu.Item>
                        <Menu.Item>
                            <Popup size='small' trigger={<Icon name='calendar' size='large' color='teal' />} content={t('Select a time-frame')} />
                            <Button name='Day' onClick={this.handleWindowClick} basic color={this.state.dayButtonColor}>{t("Past 24 Hours")}</Button>&nbsp;
                            <Button name='Week' onClick={this.handleWindowClick} basic color={this.state.hashtagWeekButtonColor}>{t("Past Week")}</Button>&nbsp;
                            <Button name='Month' onClick={this.handleWindowClick} basic color={this.state.monthButtonColor}>{t("Past Month")}</Button>&nbsp;
                            <Button name='Quarter' onClick={this.handleWindowClick} basic color={this.state.quarterButtonColor}>{t("Past Quarter")}</Button>&nbsp;
                            <Button name='Year' onClick={this.handleWindowClick} basic color={this.state.yearButtonColor}>{t("Past Year")}</Button>&nbsp;
                </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </Responsive>
            <Responsive maxWidth={600}>
                <Menu size='tiny' stackable>
                    <Menu.Menu position='left' borderless>
                        <Menu.Item>
                            <Popup size='small' trigger={<Icon name='calendar' size='large' color='teal' />} content={t('Select a time-frame')} />
                            <Button compact name='Day' onClick={this.handleWindowClick} basic color={this.state.dayButtonColor}>{t("24h")}</Button>&nbsp;
                            <Button compact name='Week' onClick={this.handleWindowClick} basic color={this.state.hashtagWeekButtonColor}>{t("Week")}</Button>&nbsp;
                            <Button compact name='Month' onClick={this.handleWindowClick} basic color={this.state.monthButtonColor}>{t("Month")}</Button>&nbsp;
                            <Button compact name='Quarter' onClick={this.handleWindowClick} basic color={this.state.quarterButtonColor}>{t("Quarter")}</Button>&nbsp;
                            <Button compact name='Year' onClick={this.handleWindowClick} basic color={this.state.yearButtonColor}>{t("Year")}</Button>&nbsp;
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </Responsive>
        </Fragment>;
    }

    InfluencersFilterMenu = () => {
        const { t } = this.props;
        return <Fragment>
            <Responsive minWidth={1025}>
                <Menu size='small' stackable borderless>
                    <Menu.Menu position='left'>
                        <Menu.Item></Menu.Item>
                        <Menu.Item>
                            <Popup size='small' trigger={<Icon name='calendar' size='large' color='teal' />} content={t('Select a time-frame')} />
                            <Button name='Week' onClick={this.handleWindowClick} basic color={this.state.influencerWeekButtonColor}>{t("Past Week")}</Button>&nbsp;
                            <Button name='Month' onClick={this.handleWindowClick} basic color={this.state.monthButtonColor}>{t("Past Month")}</Button>&nbsp;
                            <Button name='Quarter' onClick={this.handleWindowClick} basic color={this.state.quarterButtonColor}>{t("Past Quarter")}</Button>&nbsp;
                            <Button name='Year' onClick={this.handleWindowClick} basic color={this.state.yearButtonColor}>{t("Past Year")}</Button>&nbsp;
                </Menu.Item>
                    </Menu.Menu>
                    <Menu.Menu position='right'>
                        {this.getSponsorMenuItem()}
                    </Menu.Menu>
                </Menu>
            </Responsive>
            <Responsive maxWidth={1024}>
                <Menu size='tiny' stackable borderless>
                    <Menu.Menu position='left'>
                        <Responsive minWidth={1025}>
                            <Menu.Item></Menu.Item>
                        </Responsive>
                        <Menu.Item>
                            <Popup size='small' trigger={<Icon name='calendar' size='large' color='teal' />} content={t('Select a time-frame')} />
                            <Button compact name='Week' onClick={this.handleWindowClick} basic color={this.state.hashtagWeekButtonColor}>{t("Week")}</Button>&nbsp;
                            <Button compact name='Month' onClick={this.handleWindowClick} basic color={this.state.monthButtonColor}>{t("Month")}</Button>&nbsp;
                            <Button compact name='Quarter' onClick={this.handleWindowClick} basic color={this.state.quarterButtonColor}>{t("Quarter")}</Button>&nbsp;
                            <Button compact name='Year' onClick={this.handleWindowClick} basic color={this.state.yearButtonColor}>{t("Year")}</Button>&nbsp;
                </Menu.Item>
                        <Menu.Menu position='right'>
                            {this.getSponsorMenuItem()}
                        </Menu.Menu>
                    </Menu.Menu>
                </Menu>
            </Responsive>
        </Fragment>;
    }

    InfluencersPersonsMenu = () => {
        const { t } = this.props;
        return <Fragment>
            <Responsive minWidth={1025}>
                <Menu size='small' stackable borderless>
                    <Menu.Menu position='left'>
                        <Menu.Item></Menu.Item>
                        <Menu.Item>
                            <Icon name='twitter' size='large' color='teal' />
                            <Button name='Individuals' as={Link} to='/influencers/2020/individuals' basic color='teal'>{t("Individuals")}</Button>&nbsp;
                            <Button name='Organisations' as={Link} to='/influencers/2020/organisations' basic color='white'>{t("Organisations")}</Button>&nbsp;
                        </Menu.Item>
                    </Menu.Menu>
                    <Responsive minWidth={769}>
                        <Menu.Menu position='right'>
                            {this.getSponsorMenuItem()}
                        </Menu.Menu>
                    </Responsive>
                </Menu>
            </Responsive>
            <Responsive maxWidth={1024}>
                <Menu size='tiny' stackable borderless>
                    <Menu.Menu position='left'>
                        <Responsive minWidth={1025}>
                            <Menu.Item></Menu.Item>
                        </Responsive>
                        <Menu.Item>
                            <Icon name='twitter' size='large' color='teal' />
                            <Button name='Individuals' as={Link} to='/influencers/2020/individuals' basic color='teal'>{t("Individuals")}</Button>&nbsp;
                            <Button name='Organisations' as={Link} to='/influencers/2020/organisations' basic color='white'>{t("Organisations")}</Button>&nbsp;
                        </Menu.Item>
                    </Menu.Menu>
                    <Menu.Menu position='right'>
                        {this.getSponsorMenuItem()}
                    </Menu.Menu>
                </Menu>
            </Responsive>
        </Fragment>;
    }

    InfluencersOrgsMenu = () => {
        const { t } = this.props;
        return <Fragment>
            <Responsive minWidth={1025}>
                <Menu size='small' stackable borderless>
                    <Menu.Menu position='left'>
                        <Menu.Item></Menu.Item>
                        <Menu.Item>
                            <Icon name='twitter' size='large' color='teal' />
                            <Button name='Individuals' as={Link} to='/influencers/2020/individuals' basic color='white'>{t("Individuals")}</Button>&nbsp;
                            <Button name='Organisations' as={Link} to='/influencers/2020/organisations' basic color='teal'>{t("Organisations")}</Button>&nbsp;
                        </Menu.Item>
                    </Menu.Menu>
                    <Responsive minWidth={769}>
                        <Menu.Menu position='right'>
                            {this.getSponsorMenuItem()}
                        </Menu.Menu>
                    </Responsive>
                </Menu>
            </Responsive>
            <Responsive maxWidth={1024}>
                <Menu size='tiny' stackable borderless>
                    <Menu.Menu position='left'>
                        <Responsive minWidth={1025}>
                            <Menu.Item></Menu.Item>
                        </Responsive>
                        <Menu.Item>
                            <Icon name='twitter' size='large' color='teal' />
                            <Button name='Individuals' as={Link} to='/influencers/2020/individuals' basic color='white'>{t("Individuals")}</Button>&nbsp;
                            <Button name='Organisations' as={Link} to='/influencers/2020/organisations' basic color='teal'>{t("Organisations")}</Button>&nbsp;
                </Menu.Item>
                    </Menu.Menu>
                    <Menu.Menu position='right'>
                        {this.getSponsorMenuItem()}
                    </Menu.Menu>
                </Menu>
            </Responsive>
        </Fragment>;
    }

    LiveFeedMenu = () => {
        return <Menu size='small' stackable borderless>
            <Menu.Menu position='right'>
                <Responsive maxWidth={768}>
                    {this.getSponsorMenuItem()}
                </Responsive>
                <Responsive minWidth={769}>
                    {this.getSponsorMenuItem()}
                </Responsive>
            </Menu.Menu>
        </Menu>;
    }

    render() {
        return <Switch>
            <Route exact path='/' render={(props) => this.ArticlesFilterMenu()} />
            <Route path='/influencers' render={(props) => this.TrendsFilterMenu()} />
            <Route path='/articles/2021' render={(props) => this.Articles2021FilterMenu()} />
            <Route path='/articles' render={(props) => this.ArticlesFilterMenu()} />
            <Route path='/hashtags' render={(props) => this.HashTagFilterMenu()} />
            <Route path='/influencersplus' render={(props) => this.InfluencersFilterMenu()} />
        </Switch>;
    }
}

export default withTranslation()(FilterMenu);