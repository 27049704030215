import React, { Component } from 'react';
import { Segment, Grid, Header, Image, Button } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

import intro from '../images/poolstokbuzzintro.jpg';

class About extends Component {

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Segment style={{ padding: '2em 0em' }} vertical>
                    <Grid columns={2} stackable padded='horizontally' reversed='mobile vertically'>
                        <Grid.Column width={2} />
                        <Grid.Column width={8}>
                            <Segment padded='very' raised>
                                <Header as='h4' style={{ fontSize: '1.6em' }}>
                                    {t("About Poolstok")}
                                </Header>
                                <p style={{ fontSize: '1.1em' }}>
                                    {t("AboutContentOne")}
                                </p>
                                <p style={{ fontSize: '1.1em' }}>
                                    {t("AboutContentTwo")}
                                </p>
                                <p style={{ fontSize: '1.1em' }}>
                                    {t("AboutContentThree")}
                                </p>
                                <Segment basic textAlign='center'>
                                    <Button as='a' href='https://www.poolstok.be/organisaties' target='_blank' color='purple' size='large'>Bezoek onze website!</Button>
                                </Segment>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Image src={intro} rounded as='a' href='https://www.poolstok.be' target='_blank' />
                        </Grid.Column>
                        <Grid.Column width={2} />
                    </Grid>
                </Segment>
            </React.Fragment>);
    }
}

export default withTranslation()(About);