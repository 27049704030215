import React, { Component, Fragment } from 'react';
import { Grid, Image, Card, Button, Icon, Popup, Responsive, Loader } from 'semantic-ui-react';
import defaultimage from '../images/healthbuzzdefault.jpg';

class Stories extends Component {

    state = {
        begin: 0,
        end: 24
    };

    getTwitterUrl = (title, url) => {
        return 'http://twitter.com/share?text=' + title + ' via @Poolstok_&url=' + url;
    }

    getLinkedInUrl = (title, url) => {
        return 'https://www.linkedin.com/shareArticle?mini=true&url=' + url + 'title=' + title;
    }

    getFacebookUrl = (url) => {
        return 'https://www.facebook.com/sharer/sharer.php?u=' + url;
    }

    getImage = (item) => {
        this.image = item.image;
        if (!this.image) {
            this.image = defaultimage;
        }
        if (item.newsType === 'GOV') {
            return <Image src={this.image} fluid
                label={{ corner: 'right', icon: 'university', color: 'teal', size: 'large' }}
                href={item.url} target='_blank' />;
        } else if (item.newsType === 'RES') {
            return <Image src={this.image} fluid
                label={{ corner: 'right', icon: 'search', color: 'teal', size: 'large' }}
                href={item.url} target='_blank' />;
        } else {
            return <Image src={this.image} fluid
                href={item.url} target='_blank' />;
        }

    }

    getHeader = (item) => {
        if (item.newsType === 'PAY') {
            return <Card.Header><Icon bordered color='red' name='lock' size='tiny' circular /> {item.title}</Card.Header>;
        } else {
            return <Card.Header>{item.title}</Card.Header>;
        }

    }

    getDate = (date) => {
        return new Intl.DateTimeFormat('en-GB', { month: '2-digit', day: '2-digit' }).format(date);
    }

    getDescription = (item) => {
        if (item.description) {
            return <Card.Description textAlign="left">
                {this.getDate(item.date)} &nbsp;-&nbsp; {item.description}</Card.Description>;
        }
    }

    cardClick = (item) => {
    }

    getCard = (item) => {
        return <Card fluid onClick={this.cardClick.bind(this, item)}>
            {this.getImage(item)}
            <Card.Content href={item.url} target='_blank'>
                {this.getHeader(item)}
                <Card.Meta>
                    <p style={{ color: '#56BFB7' }}>{item.site}</p>
                </Card.Meta>
                {this.getDescription(item)}
            </Card.Content>
            <Card.Content extra textAlign="center">
                <Popup size='mini' trigger={
                    <Button circular icon='share alternate' size='tiny' color='teal' basic />} on='click'>
                    <Button color='twitter' circular icon='twitter' size='tiny' href={this.getTwitterUrl(item.title, item.url)} target='_blank' />
                    <Button color='linkedin' circular icon='linkedin' size='tiny' href={this.getLinkedInUrl(item.title, item.url)} target='_blank' />
                    <Button color='facebook' circular icon='facebook' size='tiny' href={this.getFacebookUrl(item.url)} target='_blank' />
                </Popup>
            </Card.Content>
        </Card>;
    }

    getColumn = (index, numberOfColumns) => {
        var column = [];
        if (this.props.items && this.props.items.length > 0) {
            for (var i = index; i < this.props.items.length; i = i + numberOfColumns) {
                column.push(this.props.items[i]);
            }
        }
        return column;
    }

    getArticleColumn = (columnItems) => {
        return <Grid.Column>
            <Grid columns={1}>
                {columnItems.slice(this.state.begin, this.state.end).map((item, index) =>
                    <Grid.Column key={index} textAlign='center'>
                        {this.getCard(item)}
                    </Grid.Column>
                )}
            </Grid>
        </Grid.Column>;
    }

    render() {
        var firstColumnOfThree = this.getColumn(0, 3);
        var secondColumnOfThree = this.getColumn(1, 3);
        var thirdColumnOfThree = this.getColumn(2, 3);
        var firstColumnOfFour = this.getColumn(0, 4);
        var secondColumnOfFour = this.getColumn(1, 4);
        var thirdColumnOfFour = this.getColumn(2, 4);
        var fourthColumnOfFour = this.getColumn(3, 4);
        if (this.props.items && this.props.items.length > 0) {
            return <Fragment>
                <Responsive minWidth={1920}>
                    <Grid columns={4} stackable padded='horizontally'>
                        {this.getArticleColumn(firstColumnOfFour)}
                        {this.getArticleColumn(secondColumnOfFour)}
                        {this.getArticleColumn(thirdColumnOfFour)}
                        {this.getArticleColumn(fourthColumnOfFour)}
                    </Grid>
                </Responsive>
                <Responsive minWidth={1024} maxWidth={1919}>
                    <Grid columns={3} stackable padded='horizontally'>
                        {this.getArticleColumn(firstColumnOfThree)}
                        {this.getArticleColumn(secondColumnOfThree)}
                        {this.getArticleColumn(thirdColumnOfThree)}
                    </Grid>
                </Responsive>
                <Responsive maxWidth={1023}>
                    <Grid stackable columns={2} padded='horizontally'>
                        {this.props.items.slice(this.state.begin, this.state.end).map((item, index) =>
                            <Grid.Column textAlign='center' key={index}>
                                {this.getCard(item)}
                            </Grid.Column>
                        )}
                    </Grid>
                </Responsive>
            </Fragment>;
        }
        else {
            return <Loader active inline='centered' />;
        }
    }
}

export default Stories;