import React, { Component, Fragment } from 'react';
import { Grid, Responsive, Message, Modal, Segment, Header, Icon, Form, Button, Card, Popup, Image, Divider, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import Stories from './Stories';
import * as BuzzConstants from './BuzzConstants';
import 'whatwg-fetch';
import { withTranslation } from 'react-i18next';
import i18n from '../i18n.js';
import defaultimage from '../images/healthbuzzdefault.jpg';
import intro from '../images/poolstokbuzzintro.jpg';

class StoriesOverviewYear extends Component {

    state = {
        modalOpen: false,
        newsItems: [],
        latestNewsItems: [],
        featured: null,
        newsUrl: BuzzConstants.NEWS_YEAR_PREVIOUS,
        loading: false,
        infoBoxVisible: true,
        firstName: '',
        lastName: '',
        email: '',
        invalidEmail: false,
        signupSuccessful: false,
        signupFailed: false,
        mostRecentTweet: null,
        activeLiveFeed: ''
    };

    handleDismiss = () => {
        localStorage.setItem('ArticleInfoBox', true);
        this.setState({ infoBoxVisible: true });
    }

    getInfoBox = () => {
        const { t } = this.props;
        var articleInfoDismissed = localStorage.getItem('ArticleInfoBox') || false;
        if (!articleInfoDismissed && this.state.infoBoxVisible) {
            return <Message size="small" onDismiss={this.handleDismiss} color='teal'>
                <Message.Header>{t("Welcome to PoolstokBuzz!")}</Message.Header>
                <p>{t("StoriesInfoBoxText")}</p>
            </Message>;
        }
    }

    loadNews() {
        fetch(this.state.newsUrl)
            .then(result => result.json())
            .then(newsItems => this.setState({ newsItems }));
    }

    loadUserData() {
        this.setState({ loading: true });
        const values = queryString.parse(this.props.location.search);
        this.loadNews();
        this.setState({ loading: false });
    }

    componentDidMount() {
        this.loadUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.newsUrl !== this.state.newsUrl) {
            this.loadNews();
        }
    }

    componentWillUnmount() {

    }

    addLineBreaks = (text) => {
        if (text) {
            return text.split('\n').map((item, key) => {
                return <Fragment key={key}>{item}<br /></Fragment>
            });
        }
    }

    getText = (text) => {
        if (text) {
            //var replacePattern = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
            //var replacedText = text.replace(replacePattern, '<Link to="$1" target="_blank">$1</Link>');
            var lineBreakText = this.addLineBreaks(text);
            return lineBreakText;
        }
    }

    getTwitterUrl = (title, url) => {
        return 'http://twitter.com/share?text=' + title + ' via @Poolstok_&url=' + url;
    }

    getLinkedInUrl = (title, url) => {
        return 'https://www.linkedin.com/shareArticle?mini=true&url=' + url + 'title=' + title;
    }

    getFacebookUrl = (url) => {
        return 'https://www.facebook.com/sharer/sharer.php?u=' + url;
    }

    getImage = (item) => {
        this.image = item.image;
        if (!this.image) {
            this.image = defaultimage;
        }
        if (item.newsType === 'GOV') {
            return <Image src={this.image} fluid
                label={{ corner: 'right', icon: 'university', color: 'teal', size: 'large' }}
                href={item.url} target='_blank' />;
        } else if (item.newsType === 'RES') {
            return <Image src={this.image} fluid
                label={{ corner: 'right', icon: 'search', color: 'teal', size: 'large' }}
                href={item.url} target='_blank' />;
        } else {
            return <Image src={this.image} fluid
                href={item.url} target='_blank' />;
        }

    }

    getHeader = (item) => {
        if (item.newsType === 'PAY') {
            return <Card.Header><Icon bordered color='red' name='lock' size='tiny' circular /> {item.title}</Card.Header>;
        } else {
            return <Card.Header>{item.title}</Card.Header>;
        }

    }

    getDate = (date) => {
        return new Intl.DateTimeFormat('en-GB', { month: '2-digit', day: '2-digit' }).format(date);
    }

    getDescription = (item) => {
        if (item.description) {
            return <Card.Description textAlign="left">
                {this.getDate(item.date)} &nbsp;-&nbsp; {item.description}</Card.Description>;
        }
    }

    cardClick = (item) => {
    }

    render() {
        return <Fragment>
            <Stories items={this.state.newsItems} />
        </Fragment >;
    }


}

export default withTranslation()(StoriesOverviewYear);