import React, { Component, Fragment } from 'react';
import { Grid, Loader, Responsive } from 'semantic-ui-react';
import TweetEmbed from 'react-tweet-embed';

class Tweets extends Component {

    state = {
        begin: 0,
        end: 24
    };

    componentDidMount() {
        this.interval = setInterval(() => this.setState({ time: Date.now() }), 60000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getColumn = (index, numberOfColumns) => {
        var column = [];
        if (this.props.items && this.props.items.length > 0) {
            for (var i = index; i < this.props.items.length; i = i + numberOfColumns) {
                column.push(this.props.items[i]);
            }
        }
        return column;
    }

    getTweetColumn = (columnItems) => {
        return <Grid.Column>
            <Grid columns={1}>
                {columnItems.slice(this.state.begin, this.state.end).map((item, index) =>
                    <Grid.Column key={index}>
                        <TweetEmbed id={item.tweetId} fluid options={{ dnt: 'true', linkColor: '#00aead' }} />
                    </Grid.Column>
                )}
            </Grid>
        </Grid.Column>;
    }

    render() {
        var firstColumnOfThree = this.getColumn(0, 3);
        var secondColumnOfThree = this.getColumn(1, 3);
        var thirdColumnOfThree = this.getColumn(2, 3);
        var firstColumnOfFour = this.getColumn(0, 4);
        var secondColumnOfFour = this.getColumn(1, 4);
        var thirdColumnOfFour = this.getColumn(2, 4);
        var fourthColumnOfFour = this.getColumn(3, 4);
        if (this.props.items && this.props.items.length > 0) {
            return <Fragment>
                <Responsive minWidth={1920}>
                    <Grid columns={4} stackable padded='horizontally'>
                        {this.getTweetColumn(firstColumnOfFour)}
                        {this.getTweetColumn(secondColumnOfFour)}
                        {this.getTweetColumn(thirdColumnOfFour)}
                        {this.getTweetColumn(fourthColumnOfFour)}
                    </Grid>
                </Responsive>
                <Responsive minWidth={1024} maxWidth={1919}>
                    <Grid columns={3} stackable padded='horizontally'>
                        {this.getTweetColumn(firstColumnOfThree)}
                        {this.getTweetColumn(secondColumnOfThree)}
                        {this.getTweetColumn(thirdColumnOfThree)}
                    </Grid>
                </Responsive>
                <Responsive maxWidth={1023}>
                    <Grid stackable padded='horizontally'>
                        <Grid.Column>
                            <Grid columns={2} stackable padded='horizontally'>
                                {this.props.items.slice(this.state.begin, this.state.end).map((item, index) =>
                                    <Grid.Column key={index}>
                                        <TweetEmbed id={item.tweetId} fluid options={{ dnt: 'true', linkColor: '#00aead' }} />
                                    </Grid.Column>
                                )}
                            </Grid>
                        </Grid.Column>
                    </Grid>
                </Responsive>
            </Fragment>;
        } else {
            return <Loader active inline='centered' />;
        }
    }
}

export default Tweets;