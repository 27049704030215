import React, { Component, Fragment } from 'react';
import { Grid, Responsive, Message, Modal, Segment, Header, Icon, Form, Button, Card, Popup, Image, Divider, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import Stories from '../components/Stories';
import * as BuzzConstants from '../components/BuzzConstants';
import 'whatwg-fetch';
import { withTranslation } from 'react-i18next';
import i18n from '../i18n.js';
import defaultimage from '../images/healthbuzzdefault.jpg';
import intro from '../images/poolstokbuzzintro.jpg';

class StoriesOverview extends Component {

    state = {
        modalOpen: false,
        newsItems: [],
        latestNewsItems: [],
        featured: null,
        newsUrl: BuzzConstants.NEWS_YEAR,
        latestNewsUrl: BuzzConstants.NEWS_LATEST,
        newsQueryUrl: BuzzConstants.NEWS_QUERY,
        loading: false,
        infoBoxVisible: true,
        firstName: '',
        lastName: '',
        email: '',
        invalidEmail: false,
        signupSuccessful: false,
        signupFailed: false,
        mostRecentTweet: null,
        activeLiveFeed: ''
    };

    handleFirstNameChange = (e, { firstName, value }) => this.setState({ firstName: value })
    handleLastNameChange = (e, { lastName, value }) => this.setState({ lastName: value })
    handleEmailChange = (e, { email, value }) => {
        this.setState({ email: value })
        if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            this.setState({ invalidEmail: true });
        } else {
            this.setState({ invalidEmail: false });
        }
    }

    signUp = (firstName, lastName, email) => {
        const that = this;
        fetch(BuzzConstants.SIGNUP, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                firstName: firstName,
                lastName: lastName,
                email: email
            })
        }).then(function (response) {
            if (response.status === 200) {
                that.setState({ signupSuccessful: true });
            } else {
                that.setState({ signupFailed: true });
            }
            return response;
        });
    }

    handleOpen = () => this.setState({ modalOpen: true })

    handleClose = () => this.setState({ modalOpen: false })

    handleSubmit = () => {
        const { firstName, lastName, email } = this.state;
        this.signUp(firstName, lastName, email);
    }

    handleDismiss = () => {
        localStorage.setItem('ArticleInfoBox', true);
        this.setState({ infoBoxVisible: true });
    }

    getInfoBox = () => {
        const { t } = this.props;
        var articleInfoDismissed = localStorage.getItem('ArticleInfoBox') || false;
        if (!articleInfoDismissed && this.state.infoBoxVisible) {
            return <Message size="small" onDismiss={this.handleDismiss} color='teal'>
                <Message.Header>{t("Welcome to PoolstokBuzz!")}</Message.Header>
                <p>{t("StoriesInfoBoxText")}</p>
            </Message>;
        }
    }

    setNewsUrl = (window, language) => {
        this.setState({ begin: 0, end: 5 });
        if (language === 'all' || language === null) {
            this.setState({ newsUrl: BuzzConstants.NEWS_YEAR });
        } else if (language === 'fr') {
            this.setState({ newsUrl: BuzzConstants.NEWS_YEAR_FR });
        } else if (language === 'nl') {
            this.setState({ newsUrl: BuzzConstants.NEWS_YEAR_NL });
        } else if (language === 'en') {
            this.setState({ newsUrl: BuzzConstants.NEWS_YEAR_EN });
        }
        /**if (window === 'Week') {
            if (language === 'all' || language === null) {
                this.setState({ newsUrl: BuzzConstants.NEWS_WEEK });
            } else if (language === 'fr') {
                this.setState({ newsUrl: BuzzConstants.NEWS_WEEK_FR });
            } else if (language === 'nl') {
                this.setState({ newsUrl: BuzzConstants.NEWS_WEEK_NL });
            } else if (language === 'en') {
                this.setState({ newsUrl: BuzzConstants.NEWS_WEEK_EN });
            }
        } else if (window === 'Month') {
            if (language === 'all' || language === null) {
                this.setState({ newsUrl: BuzzConstants.NEWS_MONTH });
            } else if (language === 'fr') {
                this.setState({ newsUrl: BuzzConstants.NEWS_MONTH_FR });
            } else if (language === 'nl') {
                this.setState({ newsUrl: BuzzConstants.NEWS_MONTH_NL });
            } else if (language === 'en') {
                this.setState({ newsUrl: BuzzConstants.NEWS_MONTH_EN });
            }
        }**/
    }

    loadNewsItem(featured) {
        fetch(this.state.newsQueryUrl + featured)
            .then(result => result.json())
            .then(featured => this.setState({ featured }));
    }

    loadNews() {
        fetch(this.state.newsUrl)
            .then(result => result.json())
            .then(newsItems => this.setState({ newsItems }));
    }

    loadLatestNews() {
        fetch(this.state.latestNewsUrl)
            .then(result => result.json())
            .then(latestNewsItems => this.setState({ latestNewsItems }));
    }

    loadUserData() {
        this.setState({ loading: true });
        const values = queryString.parse(this.props.location.search);
        if (values.featured) {
            this.loadNewsItem(values.featured);
        }
        this.loadNews();
        this.loadLatestNews();
        this.setState({ loading: false });
    }

    componentDidMount() {
        this.loadUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.newsUrl !== this.state.newsUrl) {
            this.loadNews();
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setNewsUrl(nextProps.window, nextProps.language);
    }

    componentWillUnmount() {

    }

    getEmailInput = () => {
        const { t } = this.props;
        const { email } = this.state
        if (this.state.invalidEmail) {
            return <Form.Input
                required
                error={{ content: i18n.t("InvalidEmail"), pointing: 'above' }}
                label={t("Email")}
                icon='mail'
                iconPosition='left'
                placeholder={t("Enter your email here...")}
                value={email}
                onChange={this.handleEmailChange}
            />;
        } else {
            return <Form.Input
                required
                label={t("Email")}
                icon='mail'
                placeholder={t("Enter your email here...")}
                iconPosition="left"
                value={email}
                onChange={this.handleEmailChange} />;
        }
    }

    getRequestHeader = () => {
        const { t } = this.props;
        if (this.state.signupSuccessful) {
            return <Header size='small'>{t("Thank you!")}</Header>;
        } else if (this.state.signupFailed) {
            return <Header size='small'>{t("Oops...")}</Header>;
        } else {
            return <Header size='small'>{t("Please provide your contact details")}</Header>;
        }
    }

    getModalActions = () => {
        const { t } = this.props;
        if (this.state.signupSuccessful || this.state.signupFailed) {
            return <Modal.Actions>
                <Button color='teal' onClick={this.handleClose}>
                    <Icon name='checkmark' /> {t("Got it!")}
                </Button>
            </Modal.Actions>;
        }
    }

    getRequestModalContent = () => {
        const { t } = this.props;
        const { firstName, lastName } = this.state
        if (this.state.signupSuccessful) {
            return <p><Icon name='check circle' color='green' size='large' />{t("You've been successfully subscribed for PoolstokBuzz updates!")}</p>;
        } else if (this.state.signupFailed) {
            return <p><Icon name='exclamation circle' color='red' size='large' />{t("We currently can't complete your subscription, please try again later.")}</p>;
        } else {
            return <Fragment>
                <p>{t("KeepMeInformed")}</p>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group widths='equal'>
                        <Form.Input
                            required
                            icon='user'
                            label={t("First Name")}
                            iconPosition='left'
                            placeholder={t("Enter your first name here...")}
                            value={firstName}
                            onChange={this.handleFirstNameChange} />
                        <Form.Input
                            required
                            icon='user'
                            label={t("Last Name")}
                            iconPosition='left'
                            placeholder={t("Enter your last name here...")}
                            value={lastName}
                            onChange={this.handleLastNameChange} />
                    </Form.Group>
                    {this.getEmailInput()}
                    <Button color='teal' fluid size='large'>
                        {t("Sign Me Up")}
                    </Button>
                    <p>{t("We value your privacy, read our privacy policy ")} <a href="/privacy" target="_blank">{t("here")}</a></p>
                </Form>
            </Fragment>;
        }
    }

    getYearInReviewButton = () => {
        const { t } = this.props;
        if (i18n.language === 'fr') {
            return <Button href="/2019/fr" color='teal' size='small'>{t("See The Overview")}</Button>;
        } else {
            return <Button href="/2019/nl" color='teal' size='small'>{t("See The Overview")}</Button>;
        }
    }

    getYearInReviewBox = () => {
        const { t } = this.props;
        return <Fragment>
            <Segment inverted textAlign='center'
                style={{
                    backgroundColor: '#255171',
                    textAlign: 'center'
                }}>
                <Header icon>
                    <Icon name='star outline' color='teal' />{t("THIS WAS 2019")}
                </Header>
                <p>{t("Look back at the most popular articles of the past year in Belgian healthcare.")}</p>
                {this.getYearInReviewButton()}
            </Segment>
        </Fragment>;
    }

    getTwitterTopBox = () => {
        const { t } = this.props;
        return <Fragment>
            <Segment textAlign='center'
                style={{
                    textAlign: 'center'
                }}>
                <Header icon>
                    <Icon name='twitter' color='teal' />{t("2020 Twitter Top 100")}
                </Header>
                <p></p>
                <Button.Group>
                    <Button href="/influencers/2020/individuals" color='teal' size='small'>{t("Individuals")}</Button>
                    <Button.Or text={t("or")} />
                    <Button href="/influencers/2020/organisations" color='teal' size='small'>{t("Organisations")}</Button>
                </Button.Group>
            </Segment>
        </Fragment>;
    }

    getSignUpBox = () => {
        const { t } = this.props;
        return <Fragment>
            <Segment textAlign='center'>
                <Header icon>
                    <Icon name='inbox' color='teal' />{t("Keep Me Informed")}
                </Header>
                <p>
                    <Modal onSubmit={this.handleSubmitRequest}
                        size='tiny'
                        trigger={<a href='#' onClick={this.handleOpen}> {t("Sign Up to Receive Updates")}</a>}
                        open={this.state.modalOpen}
                        onClose={this.handleClose}
                        closeIcon>
                        <Modal.Header>{t("Sign Up to Receive Updates")}</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                {this.getRequestHeader()}
                                {this.getRequestModalContent()}
                            </Modal.Description>
                        </Modal.Content>
                        {this.getModalActions()}
                    </Modal></p>
            </Segment>
        </Fragment>;
    }

    addLineBreaks = (text) => {
        if (text) {
            return text.split('\n').map((item, key) => {
                return <Fragment key={key}>{item}<br /></Fragment>
            });
        }
    }

    getText = (text) => {
        if (text) {
            //var replacePattern = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
            //var replacedText = text.replace(replacePattern, '<Link to="$1" target="_blank">$1</Link>');
            var lineBreakText = this.addLineBreaks(text);
            return lineBreakText;
        }
    }

    getLiveFeedBox = () => {
        if (this.state.mostRecentTweet && this.state.activeLiveFeed) {
            return <Fragment>
                <Segment attached textAlign='center' size='small' href='/livefeed' style={{ display: 'block' }} raised>
                    <Header>
                        <Header.Content>
                            Inspire Healthcare
                            <Header.Subheader>Live Twitter Feed</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Segment>
                <Segment attached textAlign='center' color='teal' size='small' secondary as={Link} to='/livefeed' style={{ display: 'block' }} >
                    <Fragment>
                        <p><em>{this.getText(this.state.mostRecentTweet.tweetText)}</em></p>
                        <Header color='teal' as='h5'><Icon name='twitter' color='teal' />{this.state.mostRecentTweet.userName}</Header>
                    </Fragment>
                </Segment>
            </Fragment>;
        }
    }

    getTwitterUrl = (title, url) => {
        return 'http://twitter.com/share?text=' + title + ' via @Poolstok_&url=' + url;
    }

    getLinkedInUrl = (title, url) => {
        return 'https://www.linkedin.com/shareArticle?mini=true&url=' + url + 'title=' + title;
    }

    getFacebookUrl = (url) => {
        return 'https://www.facebook.com/sharer/sharer.php?u=' + url;
    }

    getImage = (item) => {
        this.image = item.image;
        if (!this.image) {
            this.image = defaultimage;
        }
        if (item.newsType === 'GOV') {
            return <Image src={this.image} fluid
                label={{ corner: 'right', icon: 'university', color: 'teal', size: 'large' }}
                href={item.url} target='_blank' />;
        } else if (item.newsType === 'RES') {
            return <Image src={this.image} fluid
                label={{ corner: 'right', icon: 'search', color: 'teal', size: 'large' }}
                href={item.url} target='_blank' />;
        } else {
            return <Image src={this.image} fluid
                href={item.url} target='_blank' />;
        }

    }

    getHeader = (item) => {
        if (item.newsType === 'PAY') {
            return <Card.Header><Icon bordered color='red' name='lock' size='tiny' circular /> {item.title}</Card.Header>;
        } else {
            return <Card.Header>{item.title}</Card.Header>;
        }

    }

    getDate = (date) => {
        return new Intl.DateTimeFormat('en-GB', { month: '2-digit', day: '2-digit' }).format(date);
    }

    getDescription = (item) => {
        if (item.description) {
            return <Card.Description textAlign="left">
                {this.getDate(item.date)} &nbsp;-&nbsp; {item.description}</Card.Description>;
        }
    }

    cardClick = (item) => {
    }

    getFeaturedCard = () => {
        const { t } = this.props;
        if (this.state.featured) {
            return <Fragment>
                <Segment textAlign='center' basic>
                    <Header size='huge'>
                        <Icon name='hand point down' color='teal' />{t("HIGHLIGHTED ARTICLE")}</Header>
                    <Grid columns={3} textAlign='center' attached>
                        <Grid.Column width={3}></Grid.Column>
                        <Grid.Column width={10}>
                            <Card fluid onClick={this.cardClick.bind(this, this.state.featured)}>
                                {this.getImage(this.state.featured)}
                                <Card.Content href={this.state.featured.url} target='_blank'>
                                    {this.getHeader(this.state.featured)}
                                    <Card.Meta>
                                        <p style={{ color: 'teal' }}>{this.state.featured.site}</p>
                                    </Card.Meta>
                                    {this.getDescription(this.state.featured)}
                                </Card.Content>
                                <Card.Content extra textAlign="center">
                                    <Popup size='mini' trigger={
                                        <Button circular icon='share alternate' size='tiny' color='teal' basic />} on='click'>
                                        <Button color='twitter' circular icon='twitter' size='tiny' href={this.getTwitterUrl(this.state.featured.title, this.state.featured.url)} target='_blank' />
                                        <Button color='linkedin' circular icon='linkedin' size='tiny' href={this.getLinkedInUrl(this.state.featured.title, this.state.featured.url)} target='_blank' />
                                        <Button color='facebook' circular icon='facebook' size='tiny' href={this.getFacebookUrl(this.state.featured.url)} target='_blank' />
                                    </Popup>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column width={3}></Grid.Column>
                    </Grid>
                </Segment>
                <Divider />
            </Fragment>;
        }
    }

    getFeaturedCardMobile = () => {
        const { t } = this.props;
        if (this.state.featured) {
            return <Fragment>
                <Segment textAlign='center' basic>
                    <Header size='huge'>
                        <Icon name='star' color='teal' />{t("HIGHLIGHTED ARTICLE")}</Header>
                    <Card fluid onClick={this.cardClick.bind(this, this.state.featured)}>
                        {this.getImage(this.state.featured)}
                        <Card.Content href={this.state.featured.url} target='_blank'>
                            {this.getHeader(this.state.featured)}
                            <Card.Meta>
                                <p style={{ color: 'teal' }}>{this.state.featured.site}</p>
                            </Card.Meta>
                            {this.getDescription(this.state.featured)}
                        </Card.Content>
                        <Card.Content extra textAlign="center">
                            <Popup size='mini' trigger={
                                <Button circular icon='share alternate' size='tiny' color='teal' basic />} on='click'>
                                <Button color='twitter' circular icon='twitter' size='tiny' href={this.getTwitterUrl(this.state.featured.title, this.state.featured.url)} target='_blank' />
                                <Button color='linkedin' circular icon='linkedin' size='tiny' href={this.getLinkedInUrl(this.state.featured.title, this.state.featured.url)} target='_blank' />
                                <Button color='facebook' circular icon='facebook' size='tiny' href={this.getFacebookUrl(this.state.featured.url)} target='_blank' />
                            </Popup>
                        </Card.Content>
                    </Card>
                </Segment>
                <Divider />
            </Fragment>;
        }
    }

    render() {
        return <Fragment>
            <Responsive minWidth={769}>
                <Grid columns={2} stackable padded='horizontally' reversed='mobile vertically'>
                    <Grid.Column width={12}>
                        {this.getFeaturedCard()}
                        <Stories items={this.state.newsItems} />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Image src={intro} rounded as='a' href='https://www.poolstok.be' target='_blank' />
                        <Segment textAlign='center'>
                            <Header><Icon name='newspaper outline' color='teal' />De Poolstok Nieuwsbrief</Header>
                            <Divider />
                            <List size='large' relaxed>
                                <List.Item><a href="https://www.flexmail.eu/p-6e786c732169f48cd9b94c5e643f1120">Poolstok werkt - maart 2023</a></List.Item>
                                <List.Item><a href="https://cdn.flxml.eu/m-0d883e5f1caac109219c5ecfc9f198fa356bd8d78e665159">Poolstok werkt - februari 2023</a></List.Item>
                                <List.Item><a href="https://cdn.flxml.eu/m-bc077fe50d352ad4355b13b8e6e5976030da48bb55b4e8d1">Poolstok werkt - januari 2023</a></List.Item>
                                <List.Item><a href="https://cdn.flxml.eu/m-271daa35e0a1b2271f6fef57ce7802f0ddbddc562a4286a0">Poolstok werkt - december 2022</a></List.Item>
                                <List.Item><a href="https://cdn.flxml.eu/m-e051dfe79621b3eca631c6163fcfe8d15d11ca8e5344b3bb">Poolstok werkt - november 2022</a></List.Item>
                                <List.Item><a href="https://cdn.flxml.eu/r-7cbf6dd536dd547818f3209abcc72f17cafe41d0f1854852">Poolstok werkt - oktober 2022</a></List.Item>
                            </List>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Responsive>
            <Responsive maxWidth={768}>
                <Grid padded='horizontally'>
                    <Grid.Column>
                        {this.getFeaturedCardMobile()}
                    </Grid.Column>
                </Grid>
                <Stories items={this.state.newsItems} />
            </Responsive>
        </Fragment >;
    }


}

export default withTranslation()(StoriesOverview);