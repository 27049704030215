import React, { Component, Fragment } from 'react';
import { Grid, List, Label, Segment, Header, Icon, Button, Tab, Modal, Image, Responsive, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import TopTweeps from './TopTweeps';
import TweetEmbed from 'react-tweet-embed';
import { withTranslation } from 'react-i18next';
import * as BuzzConstants from '../components/BuzzConstants';
import 'whatwg-fetch';

import logo from '../images/hb-flat-icon.png';

class ExtendedHashtags extends Component {

    defaultHashtagsUrl = BuzzConstants.HASHTAGS_DAY;

    panes = [
        {
            menuItem: { key: 'tweeps', icon: 'at', content: 'Tweeps' }, render: () => <Tab.Pane key='tweeps'>
                {this.getTopTweeps()}</Tab.Pane>
        },
        {
            menuItem: { key: 'tweets', icon: 'twitter', content: 'Tweets' }, render: () => <Tab.Pane key='tweets'>
                {this.getTopTweets()}</Tab.Pane>
        }
    ]

    state = {
        hashtagItems: [],
        begin: 0,
        end: 10,
        nrOfItems: 10,
        selectedItem: null,
        selectedWindow: null,
        topTweeps: null,
        topTweets: null,
        currentTweet: 0,
        showTopTweeps: true,
        hashtagsUrl: this.defaultHashtagsUrl,
        window: 'Day',
        nrOfTweets: 10,
        total: 20
    };

    setHashtagsUrl = (window) => {
        if (window === 'Day') {
            this.setState({ hashtagsUrl: BuzzConstants.HASHTAGS_DAY });
            this.setState({ total: 20 });
            this.setState({ begin: 0 });
            this.setState({ end: 10 });
        } else if (window === 'Week') {
            this.setState({ hashtagsUrl: BuzzConstants.HASHTAGS_WEEK });
            this.setState({ total: 20 });
            this.setState({ begin: 0 });
            this.setState({ end: 10 });
        } else if (window === 'Month') {
            this.setState({ hashtagsUrl: BuzzConstants.HASHTAGS_MONTH });
            this.setState({ total: 40 });
            this.setState({ begin: 0 });
            this.setState({ end: 10 });
        } else if (window === 'Quarter') {
            this.setState({ hashtagsUrl: BuzzConstants.HASHTAGS_QUARTER });
            this.setState({ total: 50 });
            this.setState({ begin: 0 });
            this.setState({ end: 10 });
        } else if (window === 'Year') {
            this.setState({ hashtagsUrl: BuzzConstants.HASHTAGS_YEAR });
            this.setState({ total: 100 });
            this.setState({ begin: 0 });
            this.setState({ end: 10 });
        }
    };

    loadHashtags() {
        this.setState({ hashtagItems:  []});
        fetch(this.state.hashtagsUrl)
            .then(result => result.json())
            .then(hashtagItems => this.setState({ hashtagItems }));
    }

    componentDidMount() {
        this.loadHashtags();
        this._mounted = true;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.hashtagsUrl !== this.state.hashtagsUrl) {
            this.loadHashtags();
        }
        if (this.state.hashtagItems &&
            this.state.hashtagItems.length > 0 &&
            this.state.selectedItem === null) {
            const hashtagItem = this.state.hashtagItems[0];
            if (hashtagItem !== undefined) {
                this.selectLabel(hashtagItem);
            }
        } else if (this.state.hashtagItems !== prevState.hashtagItems) {
            const hashtagItem = this.state.hashtagItems[0];
            if (hashtagItem !== undefined) {
                this.selectLabel(hashtagItem);
            }
        }
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    componentWillReceiveProps(nextProps) {
        this.setHashtagsUrl(nextProps.window);
    }

    showHashTags = () => {
        this.props.showDetails('Hashtags');
        return;
    }

    next = () => {
        this.setState({
            begin: this.state.begin + this.state.nrOfItems,
            end: this.state.end + this.state.nrOfItems
        });
    }

    previous = () => {
        this.setState({
            begin: this.state.begin - this.state.nrOfItems,
            end: this.state.end - this.state.nrOfItems
        });
    }

    nextTweet = () => {
        this.setState({
            currentTweet: this.state.currentTweet + 1
        });
    }

    previousTweet = () => {
        this.setState({
            currentTweet: this.state.currentTweet - 1
        });
    }

    previousButton = () => {
        const { t } = this.props;
        if (this.state.begin !== 0) {
            return <Button icon='left arrow' labelPosition='left' content={t("Previous")} color='teal' onClick={this.previous} size='mini' />;
        } else {
            return <Button icon='left arrow' labelPosition='left' content={t("Previous")} color='teal' onClick={this.previous} size='mini' basic disabled />;
        }
    }

    nextButton = () => {
        const { t } = this.props;
        if (this.state.end !== this.state.total) {
            return <Button icon='right arrow' labelPosition='right' content={t("Next")} color='teal' onClick={this.next} size='mini' />;
        } else {
            return <Button icon='right arrow' labelPosition='right' content={t("Next")} color='teal' onClick={this.next} size='mini' basic disabled />;
        }
    }

    previousTweetButton = () => {
        const { t } = this.props;
        if (this.state.currentTweet !== 0) {
            return <Button icon='left arrow' labelPosition='left' content={t("Previous Tweet")} color='teal' onClick={this.previousTweet} size='mini' />;
        } else {
            return <Button icon='left arrow' labelPosition='left' content={t("Previous Tweet")} color='teal' onClick={this.previousTweet} size='mini' basic disabled />;
        }
    }

    nextTweetButton = () => {
        const { t } = this.props;
        if (this.state.currentTweet !== (this.state.nrOfTweets - 1) && this.state.currentTweet !== (this.state.topTweets.length - 1)) {
            return <Button icon='right arrow' labelPosition='right' content={t("Next Tweet")} color='teal' onClick={this.nextTweet} size='mini' />;
        } else {
            return <Button icon='right arrow' labelPosition='right' content={t("Next Tweet")} color='teal' onClick={this.nextTweet} size='mini' basic disabled />;
        }
    }

    getTab = () => {
        if (this.state.selectedItem && this.state.selectedItem.hashTag.trim() !== "") {
            return <Tab menu={{ color: 'teal', fluid: true, secondary: true, pointing: true }} panes={this.panes} />;
        }
    }

    getWindowLabel = () => {
        const { t } = this.props;
        if (this.props.window === 'Week') {
            return <span>{t("Past Week")}</span>;
        } else if (this.props.window === 'Month') {
            return t("Past Month");
        } else if (this.props.window === 'Quarter') {
            return t("Past Quarter");
        } else if (this.props.window === 'Year') {
            return t("Past Year");;
        } else {
            return t("Past 24 Hours");;
        }
    }

    getTopTweeps = () => {
        return <Fragment>
            <Header as='h2' textAlign='center'>
                Top #{this.state.selectedItem.hashTag} Tweeps
                </Header> <Label as='a' attached='top right' size='small'>
                {this.getWindowLabel()}
            </Label>
            <TopTweeps tweeps={this.state.topTweeps} />
        </Fragment>
    }

    getTopTweets = () => {
        return <Fragment>
            <Header as='h2' textAlign='center'>
                Top #{this.state.selectedItem.hashTag} Tweets
                </Header>
            <Label as='a' color='teal' attached='top right' size='small'>
                {this.getWindowLabel()}
            </Label>
            {this.state.topTweets.slice(0, 19).map((item, index) =>
                <TweetEmbed key={index} id={item} fluid options={{ dnt: 'true', linkColor: '#00aead' }} />
            )}
        </Fragment>
    }

    selectLabel = (item) => {
        if (this.state.selectedItem !== item) {
            this.setState({
                selectedItem: item,
                topTweeps: item.topTweeps,
                topTweets: item.tweets
            });
        }
    }

    labelSelected = (e, { item }) => {
        this.selectLabel(item);
    }

    onCloseModal = () => {
        this.setState({ open: false });
    };

    getButtonIcon = () => {
        if (this.state.showTopTweeps) {
            return <Icon name='at' />
        } else {
            return <Icon name='twitter' />;
        }
    }

    getButtonName = (item) => {
        const { t } = this.props;
        if (this.state.showTopTweets) {
            return <React.Fragment>{t("Show Top Tweeps", { string: this.state.selectedItem.hashTag })}</React.Fragment>;
        } else {
            return <React.Fragment>{t("Show Top Tweets", { string: this.state.selectedItem.hashTag })}</React.Fragment>;
        }
    }

    getLabel = (item, index) => {
        if (item === this.state.selectedItem) {
            return <Label.Group size='large'>
                <Label as='a' image color='teal'
                    item={item}
                    onClick={this.labelSelected}>{this.state.begin + index + 1}
                    <Label.Detail>#{item.hashTag}</Label.Detail>
                </Label>
                <Label basic>{item.normalizedScore}</Label>
            </Label.Group>;
        } else {
            return <Label.Group size='large'>
                <Label as='a' image basic
                    item={item}
                    onClick={this.labelSelected}>{this.state.begin + index + 1}
                    <Label.Detail>#{item.hashTag}</Label.Detail>
                </Label>
                <Label basic>{item.normalizedScore}</Label>
            </Label.Group>;
        }
    }

    getModalContent = (hashTagItem) => {
        if (this.state.showTopTweeps) {
            return <TopTweeps tweeps={hashTagItem.topTweeps} />;
        } else {
            if (this._mounted) {
                return <TweetEmbed id={hashTagItem.tweets[this.state.currentTweet]} options={{ cards: 'hidden' }} />;
            }
        }
    }

    getModalHeaderTitle = (hashTagItem) => {
        if (this.state.showTopTweeps) {
            return <React.Fragment>Top #{hashTagItem.hashTag} Tweeps</React.Fragment>;
        } else {
            return <React.Fragment>Top #{hashTagItem.hashTag} Tweets</React.Fragment>;
        }
    }

    getButtonIcon = () => {
        if (this.state.showTopTweeps) {
            return <Icon name='twitter' />;
        } else {
            return <Icon name='at' />
        }
    }

    getButtonName = (item) => {
        if (this.state.showTopTweeps) {
            return <React.Fragment>#{item.hashTag} Tweets</React.Fragment>;
        } else {
            return <React.Fragment>#{item.hashTag} Tweeps</React.Fragment>;
        }
    }

    switchMode = () => {
        this.setState({ showTopTweeps: !this.state.showTopTweeps });
    }

    switchModeAndReset = () => {
        this.setState({ showTopTweeps: !this.state.showTopTweeps, currentTweet: 0 });
    }

    handleTweetClick = () => {
        if (this.state.currentTweet < 5) {
            this.setState({ currentTweet: this.state.currentTweet + 1 });
        }
    };

    getModalButtons = (item) => {
        const { t } = this.props;
        if (this.state.showTopTweeps) {
            return <Button icon
                size='tiny'
                color='twitter'
                labelPosition='right'
                onClick={this.switchMode}>
                {this.getButtonIcon()}
                {this.getButtonName(item)}
            </Button>;
        } else {
            if (this.state.currentTweet < 4) {
                return <React.Fragment>
                    <Responsive minWidth={1025}>
                        <Button onClick={this.handleTweetClick} icon labelPosition='right' floated='left' size='tiny' compact>
                            {t("Next Tweet")}
                            <Icon name='right arrow' />
                        </Button>
                    </Responsive>
                    <Responsive maxWidth={1024}><Button onClick={this.handleTweetClick} floated='left' size='tiny' compact>
                        {t("Next")}
                    </Button>
                    </Responsive>
                    <Button icon
                        size='tiny'
                        color='twitter'
                        labelPosition='right'
                        onClick={this.switchMode}>
                        {this.getButtonIcon()}
                        {this.getButtonName(item)}
                    </Button>
                </React.Fragment>;
            } else {
                return <React.Fragment><Button icon
                    size='tiny'
                    color='twitter'
                    labelPosition='right'
                    onClick={this.switchModeAndReset}>
                    <Icon name='at' />
                    {this.getButtonIcon()}
                    {this.getButtonName(item)}
                </Button>
                </React.Fragment>;
            }
        }
    }

    getModalBody = (item) => {
        return (
            <Fragment>
                <Modal.Header size='small'>{this.getModalHeaderTitle(item)}</Modal.Header>
                <Modal.Content>
                    {this.getModalContent(item)}
                </Modal.Content>
                <Modal.Actions>
                    {this.getModalButtons(item)}
                </Modal.Actions>
            </Fragment>
        )
    }

    getHashtags = () => {
        if (this.state.hashtagItems && this.state.hashtagItems.length > 0) {
            return <List>
                {this.state.hashtagItems.slice(this.state.begin, this.state.end).map((item, index) =>
                    <List.Item key={index}>
                        {this.getLabel(item, index)}
                    </List.Item>
                )}
            </List>;
        } else {
            return <Loader active inline='centered' />;
        }
    }

    getMobileHashtags = () => {
        if (this.state.hashtagItems && this.state.hashtagItems.length > 0) {
            return <List>
                {this.state.hashtagItems.slice(this.state.begin, this.state.end).map((item, index) =>
                    <List.Item key={index}>
                        <Modal closeIcon color='teal' size='small' trigger={this.getLabel(item, index)}>
                            {this.getModalBody(item)}
                        </Modal>
                    </List.Item>
                )}
            </List>
        } else {
            return <Loader active inline='centered' />;
        }
    }

    getHashtagList = () => {
        const { t } = this.props;
        return <Segment textAlign='center' attached>
            <Header textAlign='center' dividing><Icon name='hashtag' color='teal' />
                {t("Popular Hashtags")}</Header>
            <Modal color='teal' size='small' trigger={<Label as='a' corner='right' size='tiny' icon='question' />} closeIcon>
                <Modal.Header>{t("Hashtag Ranking")}</Modal.Header>
                <Modal.Content image>
                    <Image wrapped size='medium' src={logo} />
                    <Modal.Description>
                        <p>{t("HashtagRankingOne")}</p>
                        <p>{t("HashtagRankingTwo")}</p>
                        <p>{t("HashtagRankingThree")}</p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <p>{t("Click")} <Label as={Link} to="/rankings" circular color='teal'>{t("here")}</Label> {t("HashtagRankingFour")} </p>
                </Modal.Actions>
            </Modal>
            {this.getHashtags()}
        </Segment>;
    }

    getMobileHashtagList = () => {
        const { t } = this.props;
        return <Segment textAlign='center' attached>
            <Header textAlign='center' dividing><Icon name='hashtag' color='teal' />
                {t("Popular Hashtags")}</Header>
            <Modal color='teal' size='small' trigger={<Label as='a' corner='right' size='tiny' icon='question' />} closeIcon>
                <Modal.Header>{t("Hashtag Ranking")}</Modal.Header>
                <Modal.Content image>
                    <Image wrapped size='medium' src={logo} />
                    <Modal.Description>
                        <p>{t("HashtagRankingOne")}</p>
                        <p>{t("HashtagRankingTwo")}</p>
                        <p>{t("HashtagRankingThree")}</p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <p>{t("Click")} <Label as={Link} to="/rankings" circular color='teal'>{t("here")}</Label> {t("HashtagRankingFour")} </p>
                </Modal.Actions>
            </Modal>
            {this.getMobileHashtags()}
        </Segment>;
    }

    render() {
        return <Fragment>
            <Responsive minWidth={1025}>
                <Grid columns={16} padded='horizontally'>
                    <Grid.Column width={1}>
                    </Grid.Column>
                    <Grid.Column width={4} textAlign='center'>
                        {this.getHashtagList()}
                        <Button.Group attached='bottom'>
                            {this.previousButton()} {this.nextButton()}
                        </Button.Group>
                    </Grid.Column>
                    <Grid.Column width={1}>
                    </Grid.Column>
                    <Grid.Column width={9}>
                        {this.getTab()}
                    </Grid.Column>
                </Grid>
            </Responsive>
            <Responsive maxWidth={1024}>
                <Grid padded='horizontally'>
                    <Grid.Column textAlign='center'>
                        {this.getMobileHashtagList()}
                        <Button.Group attached='bottom'>
                            {this.previousButton()} {this.nextButton()}
                        </Button.Group>
                    </Grid.Column>
                </Grid>
            </Responsive>
        </Fragment>;
    }
}

export default withTranslation()(ExtendedHashtags);