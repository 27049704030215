import React, { Component, Fragment } from 'react';
import { Grid, Header, Segment, Image, Icon, Divider, List } from 'semantic-ui-react';
import { now } from 'moment';
import { withTranslation } from 'react-i18next';

import icon from '../images/hb-flat-icon.png';

class Ranking extends Component {

    state = {
        date: now
    }

    handleChange = (event, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    }

    render() {
        const { t } = this.props;
        return (<Fragment>
            <Header as='h2' color='teal' textAlign='center'>
                <Image src={icon} /> {t("PoolstokBuzz Social Media Rankings")}
                    </Header>
            <Divider horizontal hidden />
            <Grid columns={16} stackable padded='horizontally' reversed='mobile vertically'>
                <Grid.Column width={1}>
                </Grid.Column>
                <Grid.Column width={7}>
                    <Segment textAlign='center' padded='very'>
                        <Header><Icon name='twitter' color='teal' />{t("Online Community")}</Header>
                        <p>Poolstok.Buzz is een initiatief van Poolstok. {t("OnlineCommunityContentOne")}</p>
                        <p>{t("OnlineCommunityContentTwo")}</p>
                        <p>{t("OnlineCommunityContentThree")} CIRQLE scores {t("OnlineCommunityContentFour")}</p>
                    </Segment>
                    <Segment>
                        <Header textAlign='center'><Icon name='question circle outline' color='teal' />{t("Why These Rankings?")}</Header>
                        <p>{t("WhyRankingsContentOne")}</p>
                        <p>{t("WhyRankingsContentTwo")}</p></Segment>
                </Grid.Column>
                <Grid.Column width={7}>
                    <Segment>
                        <Header textAlign='center'><Icon name='hashtag' color='teal' />{t("Popular Hashtags")}</Header>
                        <p>{t("PopularHashtagsContentOne")}</p>
                        <p>{t("PopularHashtagsContentTwo")}</p>
                        <p>{t("PopularHashtagsContentThree")}</p>
                        <p>{t("PopularHashtagsContentFour")}</p>
                    </Segment>
                    <Segment>
                        <Header textAlign='center'><Icon name='twitter' color='teal' />{t("Healthcare Influencers")}</Header>
                        <p>{t("HealthcareInfluencersContentOne")}</p>
                        <p>{t("HealthcareInfluencersContentTwo")}<a href="https://en.wikipedia.org/wiki/Metcalfe%27s_law">Metcalfe’s Law</a>:</p>
                        <List bulleted >
                            <List.Item>{t("HealthcareInfluencersListOne")}</List.Item>
                            <List.Item>{t("HealthcareInfluencersListTwo")}</List.Item>
                            <List.Item>{t("HealthcareInfluencersListThree")}</List.Item>
                            <List.Item>{t("HealthcareInfluencersListFour")}</List.Item>
                        </List>
                        <p>{t("HealthcareInfluencersContentThree")}</p>
                        <p>{t("HealthcareInfluencersContentFour")}</p>
                    </Segment>
                </Grid.Column>
                <Grid.Column width={1}>
                </Grid.Column>
            </Grid>
        </Fragment >
        );
    }

}

export default withTranslation()(Ranking);