import React, { Component, Fragment } from 'react';
import { Button, Message, Segment, Header, Divider, Form } from 'semantic-ui-react';
import queryString from 'query-string';
import ClassifiableStories from './ClassifiableStories';
import * as BuzzConstants from './BuzzConstants';
import 'whatwg-fetch';
import { withTranslation } from 'react-i18next';

class ClassifiableStoriesOverview extends Component {

    state = {
        modalOpen: false,
        newsItems: [],
        latestNewsItems: [],
        featured: null,
        newsUrl: BuzzConstants.SCORED_NEWS,
        loading: false,
        infoBoxVisible: true,
        firstName: '',
        lastName: '',
        email: '',
        invalidEmail: false,
        signupSuccessful: false,
        signupFailed: false,
        mostRecentTweet: null,
        activeLiveFeed: ''
    };

    handleArticleLinkChange = (e, { articleLink, value }) => this.setState({ articleLink: value })


    handleDismiss = () => {
        localStorage.setItem('ArticleInfoBox', true);
        this.setState({ infoBoxVisible: true });
    }

    getInfoBox = () => {
        const { t } = this.props;
        var articleInfoDismissed = localStorage.getItem('ArticleInfoBox') || false;
        if (!articleInfoDismissed && this.state.infoBoxVisible) {
            return <Message size="small" onDismiss={this.handleDismiss} color='teal'>
                <Message.Header>{t("Welcome to PoolstokBuzz!")}</Message.Header>
                <p>{t("StoriesInfoBoxText")}</p>
            </Message>;
        }
    }

    loadNews() {
        fetch(this.state.newsUrl)
            .then(result => result.json())
            .then(newsItems => this.setState({ newsItems }));
    }

    loadUserData() {
        this.setState({ loading: true });
        const values = queryString.parse(this.props.location.search);
        if (values.featured) {
            this.loadNewsItem(values.featured);
        }
        this.loadNews();
        this.setState({ loading: false });
    }

    componentDidMount() {
        this.loadUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.newsUrl !== this.state.newsUrl) {
            this.loadNews();
        }
    }

    componentWillReceiveProps(nextProps) {

    }

    componentWillUnmount() {

    }

    postArticleLink = (urlParam) => {
        const that = this;
        fetch(BuzzConstants.NEWS + "?url=" + urlParam , {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(function (response) {
            if (response.status === 200) {
                that.setState({ articleAdded: true, urlParam: '' });
            } else {
                that.setState({ articleAdded: false, urlParam: '' });
            }
            return response;
        });
    }

    addArticle = (articleLink) => {
        if (articleLink) {
            this.postArticleLink(articleLink);
        }
    }

    handleSubmit = () => {
        const { articleLink } = this.state;
        this.addArticle(articleLink);
    }

    getArticleAddedMessage = () => {
        if (this.state.articleAdded === true) {
            return <Message success>Het artikel is toegevoegd!</Message>;
        } else if (this.state.articleAdded === false) {
            return <Message error>Het artikel kon niet worden toegevoegd.</Message>;
        }
    } 
 
    getAddArticleSegment = () => {
        const { articleLink } = this.state;
        return <Segment basic>
            <Segment color='teal' size='mini' raised>
                <Header>Voeg een artikel toe</Header>
                <Divider />
                <Form color='teal' onSubmit={this.handleSubmit}>
                    <Form.Input required
                        icon='linkify' color='teal'
                        label='Link naar het artikel'
                        iconPosition='left'
                        placeholder='https://...'
                        value={articleLink}
                        onChange={this.handleArticleLinkChange} />
                    <Button color='teal'>Voeg Toe</Button>
                </Form>
                {this.getArticleAddedMessage()}
            </Segment>
        </Segment>;
    }

    render() {
        return <Fragment>
            {this.getAddArticleSegment()}
            <ClassifiableStories items={this.state.newsItems} />
        </Fragment >;
    }


}

export default withTranslation()(ClassifiableStoriesOverview);