import React, { Component } from 'react';
import { Item, Button, Label, List, Responsive, Loader } from 'semantic-ui-react';
import 'whatwg-fetch';

class TopTweeps extends Component {

  baseUrl = "https://api.poolstok.buzz/restapi/tweeps?ids=";

  constructor() {
    super();
    this.state = {
      userProfiles: [],
      url: "",
      end: 0
    };
  }

  loadUserProfiles = () => {
    this.setState({ userProfiles: [] });
    fetch(this.state.url)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(userProfiles => this.setState({ userProfiles }))
      .catch(error => console.log(error));
  }

  componentDidMount() {
    var tweeps = this.props.tweeps.split(":");
    var nrOfTweeps = tweeps.length;
    var tweepsQuery = "";
    for (var i = 0; i < nrOfTweeps; i++) {
      if (i === 0) {
        tweepsQuery = tweeps[i].split("-")[0];
      } else {
        tweepsQuery = tweepsQuery + ";" + tweeps[i].split("-")[0];
      }
    }
    this.setState({ url: this.baseUrl + tweepsQuery, end: 5 });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tweeps !== this.props.tweeps) {
      var tweeps = nextProps.tweeps.split(":");
      var nrOfTweeps = tweeps.length;
      var tweepsQuery = "";
      for (var i = 0; i < nrOfTweeps; i++) {
        if (i === 0) {
          tweepsQuery = tweeps[i].split("-")[0];
        } else {
          tweepsQuery = tweepsQuery + ";" + tweeps[i].split("-")[0];
        }
      }
      this.setState({ url: this.baseUrl + tweepsQuery, end: 5 });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.url !== this.state.url) {
      this.loadUserProfiles();
    }
  }

  getUserProfiles = () => {
    if (this.state.userProfiles && this.state.userProfiles.length > 0) {
      return <React.Fragment>
        <Item.Group size='mini'>
          {this.state.userProfiles.slice(0, this.state.end).map((item, index) =>
            <Item key={index}>
              <Item.Image size='tiny' rounded src={item.profileImageUrl} />
              <Item.Content>
                <Item.Header>{item.name}
                  <Button size='mini' circular floated='left' color='twitter' icon='twitter' href={'https://twitter.com/' + item.screenName} target='_blank' />
                </Item.Header>
                <Item.Description>{item.description}</Item.Description>
              </Item.Content>
            </Item>)}
        </Item.Group>
        </React.Fragment>;
    } else {
      return <React.Fragment>
        <Loader active inline='centered' />
      </React.Fragment>;
    }
  }

  getMobileUserProfiles = () => {
    if (this.state.userProfiles && this.state.userProfiles.length > 0) {
      return <React.Fragment>
        <List verticalAlign='middle'>
          {this.state.userProfiles.slice(0, this.state.end).map((item, index) =>
            <List.Item key={index}>
              <Label.Group size='large'>
                <Label as='a' image>
                  <img src={item.profileImageUrl} alt='twitter-pic' />
                  {index + 1}
                  <Label.Detail>{item.name}</Label.Detail>
                </Label>
              </Label.Group>
            </List.Item>)}
        </List>
      </React.Fragment>;
    } else {
      return <React.Fragment>
        <Loader active inline='centered' />
      </React.Fragment>;
    }
  }

  render() {
    return (
      <React.Fragment>
        <Responsive minWidth={1025}>
          {this.getUserProfiles()}
        </Responsive>
        <Responsive maxWidth={1024}>
          {this.getMobileUserProfiles()}
        </Responsive>
      </React.Fragment>);
  }
}

export default TopTweeps;