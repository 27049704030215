import React, { Component } from 'react';
import { Menu, Icon, Image, Responsive } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import logo from '../images/poolstokbuzz-logo2.png';
import icon from '../images/hb-flat-icon.png';

class BaseMenu extends Component {

    state = {
        activeItem: 'articles'
    };

    handleItemClick = (e, { name }) => {
        this.setState({ activeItem: name });
        if (name === 'articles') {
            this.props.menuChoice('Articles');
        } else if (name === 'influencers') {
            this.props.menuChoice('Influencers');
        } else if (name === 'menu') {
            this.props.sideBarClick();
            this.props.menuChoice('Menu');
        }
    }

    isArticlesActive = () => {
        console.log(window.location.href);
        if (window.location.href.includes('articles') ||
                window.location.href.endsWith('poolstok.buzz') ||
                window.location.href.endsWith('poolstok.buzz/')) {
            return true;
        } 
    }

    isInfluencersActive = () => {
        if (window.location.href.includes("trends") ||
                window.location.href.includes("hashtags") ||
                window.location.href.includes("influencers")) {
            return true;
        } 
    }

    isMenuActive = () => {
        if (!window.location.href.includes("articles") && 
                !window.location.href.includes("trends") &&
                !window.location.href.includes("hashtags") &&
                !window.location.href.includes("influencers") &&
                !window.location.href.endsWith('poolstok.buzz') && 
                !window.location.href.endsWith('poolstok.buzz/')){
            return true;
        } 
    }

    render() {
        const { t } = this.props;
        return (<Menu fluid inverted icon='labeled' widths={3} borderless>
            <Menu.Menu>
                <Responsive minWidth={1025}>
                    <Menu.Item>
                        <Image src={logo}
                            as={Link} to='/articles' className='Logo'/>
                    </Menu.Item>
                </Responsive>
                <Responsive maxWidth={1024}>
                    <Menu.Item>
                        <Image src={icon} size='mini'
                            as={Link} to='/articles' align='right' />
                    </Menu.Item>
                </Responsive>
            </Menu.Menu>
            <Menu.Menu position='right'>
                <Menu.Item name='articles'
                    as={Link} to='/articles'
                    active={true}
                    onClick={this.handleItemClick} color='teal'>
                    <Icon name='newspaper outline' />
                    {t("Articles")}
                </Menu.Item>
                <Menu.Item
                    name='influencers'
                    as={Link} to='/influencers'
                    active={this.isInfluencersActive()}
                    onClick={this.handleItemClick} color='teal'>
                    <Icon name='twitter' />
                    Influencers
            </Menu.Item>
                <Menu.Item
                    name='menu'
                    active={this.isMenuActive()}
                    onClick={this.handleItemClick} color='teal'>
                    <Icon name='bars' />
                    Menu
            </Menu.Item>
            </Menu.Menu>
        </Menu>);
    }
}

export default withTranslation()(BaseMenu);;