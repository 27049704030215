import React, { Component, Fragment } from 'react';
import { Button, List, Header, Segment, Icon, Label, Loader, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class BuzzSegment extends Component {

    state = {
        begin: 0,
        end: 10
    };

    handleHashtagsButtonClick = () => {
        this.props.menuChoice('Hashtags');
    }

    handleInfluencersButtonClick = () => {
        this.props.menuChoice('Influencers');
    }

    showHashtags() {
        if (this.props.hashtags && this.props.hashtags.length > 0) {
            return <List>
                {this.props.hashtags.slice(this.state.begin, this.state.end).map((item, index) =>
                    <List.Item key={index}>
                        <Label.Group size='large'>
                            <Label as={Link} to='/hashtags' image basic>{index + 1}
                                <Label.Detail>#{item.hashTag}</Label.Detail>
                            </Label>
                        </Label.Group>
                    </List.Item>
                )}
            </List>;
        } else {
            return <React.Fragment>
                <Loader active inline='centered' />
                <Divider hidden />
            </React.Fragment>;
        }
    }

    showInfluencers() {
        if (this.props.influencers && this.props.influencers.length > 0) {
            return <List verticalAlign='middle'>
                {this.props.influencers.slice(this.state.begin, this.state.end).map((item, index) =>
                    <List.Item key={index}>
                        <Label.Group size='large'>
                            <Label as={Link} to='/influencersplus' image basic>
                                <img src={item.profileImageUrl} alt='' />
                                {item.ranking}
                                <Label.Detail>{item.userName.substring(0, 30)}</Label.Detail>
                            </Label>
                        </Label.Group>
                    </List.Item>
                )}
            </List>;
        } else {
            return <React.Fragment>
                <Loader active inline='centered' />
                <Divider hidden />
            </React.Fragment>;
        }
    }

    getInfluencerSegment = () => {
        const { t } = this.props;
        return <Fragment textAlign='center'>
            <Header icon textAlign='center' size='large' attached='top'  color='teal'>
                <Icon name='twitter' color='teal' />
                <Header.Content>{t("Healthcare Influencers")}<Header.Subheader>{t("Past Week")}</Header.Subheader></Header.Content>
            </Header>
            <Segment textAlign='center' raised attached>
                {this.showInfluencers()}
                <Button as={Link} to='/influencersplus' color='teal' size='large' onClick={this.handleInfluencersButtonClick}>{t("Show More")}</Button>
            </Segment>
        </Fragment>
    }

    getHashtagSegment = () => {
        const { t } = this.props;
        return <Fragment textAlign='center'>
            <Header icon textAlign='center' size='large' attached='top' color='teal'>
                <Icon name='twitter' color='teal' />
                <Header.Content>{t("Popular Hashtags")}<Header.Subheader>{t("Past Week")}</Header.Subheader></Header.Content>
            </Header>
            <Segment textAlign='center' attached>
                {this.showHashtags()}
                <Button as={Link} to='/hashtags' color='teal' size='large' onClick={this.handleHashtagsButtonClick}>{t("Show More")}</Button>
            </Segment>
        </Fragment>
    }

    render() {
        const { t } = this.props;
        if (this.props.mode == 'influencers') {
            return this.getInfluencerSegment();
        } else if (this.props.mode == 'hashtags') {
            return this.getHashtagSegment();
        }
        return null;
    }
}

export default withTranslation()(BuzzSegment);