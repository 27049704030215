import React, { Component, Fragment } from 'react';
import { Grid, Responsive, Message, Segment, Header, Icon, Button } from 'semantic-ui-react';
import Tweets from '../components/Tweets.jsx';
import BuzzSegment from '../components/BuzzSegment.jsx';
import * as BuzzConstants from '../components/BuzzConstants.jsx';
import 'whatwg-fetch';
import { withTranslation } from 'react-i18next';

class TrendsOverview extends Component {


    defaultInfluencersUrl = BuzzConstants.INFLUENCERS_WEEK;

    constructor() {
        super();
        this.state = {

            influencerItems: [],
            hashtagItems: [],

            hashtagsUrl: BuzzConstants.HASHTAGS_WEEK,
            weekInfluencersUrl: BuzzConstants.INFLUENCERS_WEEK,

            begin: 0,
            end: 5,
            nrOfItems: 5,
            total: 20,
            infoBoxVisible: true
        };
    }

    handleDismiss = () => {
        localStorage.setItem('TweetsInfoBox', true);
        this.setState({ infoBoxVisible: true });
    }

    getInfoBox = () => {
        const { t } = this.props;
        var tweetsInfoDismissed = localStorage.getItem('TweetsInfoBox') || false;
        if (!tweetsInfoDismissed && this.state.infoBoxVisible) {
            return <Message size="small" onDismiss={this.handleDismiss} color='teal'>
                <Message.Header>{t("Belgian Healthcare Tweets")}</Message.Header>
                <p>{t("TweetsInfoBoxText")}</p>
            </Message>;
        }
    }

    setHashtagsUrl = (window) => {
        this.setState({ begin: 0, end: 5 });
        if (window === 'Day') {
            this.setState({ hashtagsUrl: BuzzConstants.HASHTAGS_DAY });
            this.setState({ total: 20 });
        } else if (window === 'Week') {
            this.setState({ hashtagsUrl: BuzzConstants.HASHTAGS_WEEK });
            this.setState({ total: 20 });
        } else if (window === 'Month') {
            this.setState({ hashtagsUrl: BuzzConstants.HASHTAGS_MONTH });
            this.setState({ total: 40 });
        } else if (window === 'Quarter') {
            this.setState({ hashtagsUrl: BuzzConstants.HASHTAGS_QUARTER });
            this.setState({ total: 50 });
        } else if (window === 'Year') {
            this.setState({ hashtagsUrl: BuzzConstants.HASHTAGS_YEAR });
            this.setState({ total: 200 });
        }
    };

    setInfluencersUrl = (window) => {
        this.setState({ begin: 0, end: 5 });
        if (window === 'Day') {
            this.setState({ influencersUrl: BuzzConstants.INFLUENCERS_WEEK });
            this.setState({ total: 20 });
        } else if (window === 'Week') {
            this.setState({ influencersUrl: BuzzConstants.INFLUENCERS_WEEK });
            this.setState({ total: 20 });
        } else if (window === 'Month') {
            this.setState({ influencersUrl: BuzzConstants.INFLUENCERS_MONTH });
            this.setState({ total: 40 });
        } else if (window === 'Quarter') {
            this.setState({ influencersUrl: BuzzConstants.INFLUENCERS_QUARTER });
            this.setState({ total: 50 });
        } else if (window === 'Year') {
            this.setState({ influencersUrl: BuzzConstants.INFLUENCERS_YEAR });
            this.setState({ total: 100 });
        }
    };

    loadHashtags() {
        fetch(this.state.hashtagsUrl)
            .then(result => result.json())
            .then(hashtagItems => this.setState({ hashtagItems }));
    }

    loadWeekInfluencers() {
        fetch(this.state.weekInfluencersUrl)
            .then(result => result.json())
            .then(influencerItems => this.setState({ influencerItems }));
    }

    loadUserData() {
        this.loadHashtags();
        this.loadWeekInfluencers();
    }

    componentDidMount() {
        this.loadUserData();
    }

    componentWillReceiveProps(nextProps) {
        this.setHashtagsUrl(nextProps.window);
        this.setInfluencersUrl(nextProps.window);
    }

    render() {
        return <Fragment>
            <Responsive minWidth={1025}>
                <Grid columns={4} stackable padded='horizontally'>
                    <Grid.Column width={1} />
                    <Grid.Column width={7}>
                        <BuzzSegment hashtags={this.state.hashtagItems}
                            influencers={this.state.influencerItems}
                            menuChoice={this.props.menuChoice} mode={'influencers'} />
                    </Grid.Column>
                    <Grid.Column width={7}>
                        <BuzzSegment hashtags={this.state.hashtagItems}
                            influencers={this.state.influencerItems}
                            menuChoice={this.props.menuChoice} mode={'hashtags'} />
                    </Grid.Column>
                    <Grid.Column width={1} />
                </Grid>
            </Responsive>
            <Responsive maxWidth={1024} minWidth={901}>
                <Grid columns={2} stackable padded='horizontally'>
                    <Grid.Column width={1} />
                    <Grid.Column width={7}>
                        <BuzzSegment hashtags={this.state.hashtagItems}
                            influencers={this.state.influencerItems}
                            menuChoice={this.props.menuChoice} mode={'influencers'} />
                    </Grid.Column>
                    <Grid.Column width={7}>
                        <BuzzSegment hashtags={this.state.hashtagItems}
                            influencers={this.state.influencerItems}
                            menuChoice={this.props.menuChoice} mode={'hashtags'} />
                    </Grid.Column>
                    <Grid.Column width={1} />
                </Grid>
            </Responsive>
            <Responsive maxWidth={900}>
                <Grid columns={2} stackable padded='horizontally' reversed='mobile vertically'>
                    <Grid.Column width={8}>
                        <BuzzSegment hashtags={this.state.hashtagItems}
                            influencers={this.state.influencerItems}
                            menuChoice={this.props.menuChoice} mode={'influencers'} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <BuzzSegment hashtags={this.state.hashtagItems}
                            influencers={this.state.influencerItems}
                            menuChoice={this.props.menuChoice} mode={'hashtags'} />
                    </Grid.Column>
                </Grid>
            </Responsive>
        </Fragment>;
    }
}

export default withTranslation()(TrendsOverview);