import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import i18n from "./i18n";
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from "react-i18next";
import 'semantic-ui-less/semantic.less';

import './styles/index.css';


ReactDOM.render((
  <I18nextProvider i18n={i18n}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </I18nextProvider>
), document.getElementById('root'));
