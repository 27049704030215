import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    nl: {
      translations: {
        "Latest Buzz": "Laatste Nieuws",
        "Started Trending": "{{number}} uur geleden populair geworden.",
        "Articles": "Artikels",
        "Past 24 Hours": "Afgelopen 24 uur",
        "Past Week": "Afgelopen week",
        "Past Month": "Afgelopen maand",
        "Past Quarter": "Afgelopen kwartaal",
        "Past Year": "Afgelopen jaar",
        "24h": "24u",
        "Week": "Week",
        "Month": "Maand",
        "Quarter": "Kwartaal",
        "Year": "Jaar",
        "Filter": "Filter",
        "All Languages": "Alle Talen",
        "Popular Hashtags": "Populaire Hashtags",
        "Healthcare Influencers": "Influencers",
        "Show More": "Toon Meer",
        "Language": "Taal",
        "Choose Your Language": "Kies Uw Taal",
        "Calendar": "Kalender",
        "Rankings": "Rankings",
        "About": "Over Poolstok",
        "Next": "Volgende",
        "Previous": "Vorige",
        "Next Tweet": "Volgende Tweet",
        "Previous Tweet": "Vorige Tweet",
        "Register Your Event": "Registreer Jouw Event",
        "Show Events": "Toon Events",
        "Welcome to PoolstokBuzz!": "Welkom op PoolstokBuzz!",
        "StoriesInfoBoxText": "Deze pagina met artikels toont jou de meest populaire artikels in de Nederlandstalige P&O Community.",
        "Belgian Healthcare Tweets": "Tweets uit de Nederlandstalige P&O Community",
        "TweetsInfoBoxText": "Deze tweets pagina toont je de meest populaire tweets, hashtags en influencers in de Nederlandstalige P&O Community.",
        "Select a time-frame": "Kies een periode",
        "Select a language": "Kies een taal",
        "Belgian Healthcare Events": "Events in de Nederlandstalige P&O Community",
        "CalendarInfoBoxTextOne": "De PoolstokBuzz kalendar geeft je een overzicht van de aankomende events en conferenties in de Nederlandstalige P&O Community.",
        "CalendarInfoBoxTextTwo": "Je kan je eigen P&0 events registreren via de knop bovenaan de pagina.",
        "Public Event": "Publiek event",
        "Find Out More": "Meer Over Dit Event",
        "Show All": "Toon Alle",
        "January": "Januari",
        "February": "Februari",
        "March": "Maart",
        "April": "April",
        "May": "Mei",
        "June": "Juni",
        "July": "Juli",
        "August": "Augustus",
        "September": "September",
        "October": "October",
        "November": "November",
        "December": "December",
        "Show Top Tweeps": "Toon #{{string}} Tweeps",
        "Show Top Tweets": "Toon #{{string}} Tweets",
        "Hashtag Ranking": "Hashtag Ranking",
        "HashtagRankingOne": "De hashtag ranking toont de meest gebruikte hashtags binnen de Nederlandstalige P&O Community.",
        "HashtagRankingTwo": "De filterbalk bovenaan laat je toe om de periode voor de ranking aan te passen. Op die manier kan je de meest gebruikte hashtags van de afgelopen 24u, week, maand, kwartaal of jaar zien.",
        "HashtagRankingThree": "De ranking toont je tevens de meest actieve gebruikers en meest populaire tweets voor elke hashtag.",
        "HashtagRankingFour": "om meer te weten over hoe deze rankings worden opgesteld",
        "Click": "Klik",
        "here": "hier",
        "Tweets About": "Tweet Over",
        "Twitter Bio": "Twitter Bio",
        "Top Influencers": "Top Influencers",
        "Influencer Ranking": "Influencer Ranking",
        "InfluencerRankingOne": "De influencer ranking toont de Nederlandstalige P&O professionals die er het best in slagen om hun bericht te verspreiden in de P&0 community op Twitter.",
        "InfluencerRankingTwo": "De filterbalk bovenaan laat je toe om de periode voor de ranking aan te passen. Op die manier kan je de meest invloedrijke tweeps van de afgelopen 24u, week, maand, kwartaal of jaar zien.",
        "InfluencerRankingThree": "Door een influencer te selecteren kan je meer over hen te weten komen, waarover ze tweeten en met hen connecteren op Twitter.",
        "Fetching latest buzz...": "Opvragen laatste artikels...",
        "Fetching latest tweets...": "Opvragen laatste tweets...",
        "About Poolstok": "Over Poolstok",
        "AboutContentOne": "Poolstok versterkt de wendbaarheid, veerkracht en efficiëntie van publieke en non-profitorganisaties door gespecialiseerde P&O-expertise in te zetten, dààr waar ze nodig is. We doen dit niet alleen, maar samen met ons netwerk van meer dan 60 partners uit 15 verschillende P&O-domeinen.",
        "AboutContentTwo": "We ontzorgen onze klanten door voor hen op zoek te gaan naar de beste expertise op de markt. Overheidsopdrachten zijn tijdsintensief en zo creëren we ruimte voor kerntaken. We ontzorgen door scherpe tarieven te onderhandelen; met meer dan 450 klanten leggen we samen het nodige gewicht in de schaal. Maar we ontzorgen vooral door de expertise van onze partners zorgvuldig te screenen. Bewezen kwaliteit vormt de basis van elk partnership en staat centraal binnen onze werking. Sterker nog, de visie op bewezen kwaliteit is gestoeld op onze organisatiewaarden.",
        "AboutContentThree": "Zo garanderen we een kwalitatieve dienstverlening tegen een correcte prijs. Flexibel, snel en altijd op maat van de noden en de context. Beloofd.",
        "Amplifying The Belgian Healthcare Community": "De Pols van de P&0 Community",
        "AmplifyingContent": "Blijf op de hoogte van wat er omgaat in de P&0 Community: de meest populaire artikels, niet te missen events, de top trends en de influencers uit de P&0 community.",
        "Community Sourced Content": "Nieuws uit de P&0 community",
        "CommunityContent": "Alle inhoud - zoals het nieuws, trends en influencers - is gebaseerd op de online activiteit van de P&0 Community.",
        "PoolstokBuzz Social Media Rankings": "PoolstokBuzz Sociale Media Ranking",
        "PoolstokBuzz builds upon the": "PoolstokBuzz bouwt verder op de",
        "#BeHealth online Belgian healthcare community": "#BeHealth online Belgische zorgcommunity",
        "which was started in 2017": "die gestart is in 2017",
        "OnlineCommunityContentOne": " Het volgt de interacties van de P&O Community op Twitter en maakt gebruik van analytics om hier inzichten uit te halen.",
        "OnlineCommunityContentTwo": "Deze pagina beschrijft hoe de hashtag en influencer rankings worden opgebouwd.",
        "OnlineCommunityContentThree": "De scoremechanismes die worden gebruikt en hier zijn beschreven zijn de",
        "OnlineCommunityContentFour": "gedefinieerd door het onderliggende platform waar PoolstokBuzz is op gebouwd.",
        "Why These Rankings?": "Waarom Deze Rankings?",
        "WhyRankingsContentOne": "Wat verschillend is ten opzichte van andere sociale media rankings is dat PoolstokBuzz louter gebaseerd is op interacties binnen de groep van Nederlandstalige P&O professionals.",
        "WhyRankingsContentTwo": "Het scoremechanisme houdt geen rekening met het aantal volgers dat een Twitter gebruiker heeft omdat dit op Twitter niet altijd een teken is van invloed. Er zijn vele zogenoemde 'bots' op Twitter die je automatisch volgende wanneer je tweet met een bepaalde hashtag bijvoorbeeld. Door enkel te monitoren hoe invloedrijk iemand is binnen een bepaalde community is het aantal volgers an sich irrelevant.",
        "PopularHashtagsContentOne": "De berekening van de hashtag scores die je ziet in de populaire hashtag ranking is vrij vanzelfsprekend.",
        "PopularHashtagsContentTwo": "De score is gebaseerd op het aantal keer dat een hashtag wordt gebruikt in een tweet vermenigdvuldigd met het aantal P&0 professionals die interageren met de hashtag. Een interactie kan een tweet, like, commentaar of retweet zijn.",
        "PopularHashtagsContentThree": "De scores zijn relatief ten op zichte van de meest populaire hashtag die de maximum score van 100 krijgt.",
        "PopularHashtagsContentFour": "Hashtag rankings worden uurlijks berekend voor de 24 uur ranking. De andere rankings worden dagelijks geupdated.",
        "HealthcareInfluencersContentOne": "Het influencer scoremechanisme is gebaseerd of 4 verschillende elementen die worden gecombineerd tot één score.",
        "HealthcareInfluencersContentTwo": "De volgende 4 elementen worden berekend via ",
        "HealthcareInfluencersListOne": "Het aantal keer dat de tweets van een gebruiker worden geliked in relatie tot het aantal verschillende gebruikers die de tweets hebben geliked.",
        "HealthcareInfluencersListTwo": "Het aantal keer dat de tweets van een gebruiker worden geretweet in relatie tot het aantal verschillende gebruikers die de tweets hebben geretweet.",
        "HealthcareInfluencersListThree": "Het aantal interacties dat een gebruiker heeft gehad (via de Twitter reply functionaliteit) in relatie tot het aantal verschillende gebruikers met wie interactie heeft plaatsgevonden.",
        "HealthcareInfluencersListFour": "Het aantal keer dat een gebruiker is vermeld (mentions) in relatie tot het aantal verschillende gebruikers die de gebruiker hebben vermeld.",
        "HealthcareInfluencersContentThree": "Deze 4 elementen worden gewogen en gecombineerd tot één score die leidt tot de ranking.",
        "HealthcareInfluencersContentFour": "De scores zijn relatief ten op zichte van de score van de meest invloedrijke gebruiker die de maximum score van 100 krijgt.",
        "Hey! What's on your mind?": "Hallo! Heb je een vraag voor ons?",
        "ContactUsContentOne": "PoolstokBuzz wil het gemakkelijker voor je maken om up to date te blijven met wat er gebeurd in de Nederlandstalige P&O Community.",
        "ContactUsContentTwo": "Jouw ideeën doen er toe doe.",
        "ContactUsContentThree": "Hoe ervaar je PoolstokBuzz? Heb je een vraag? Laat het ons weten!",
        "Name": "Naam",
        "Email": "Email",
        "Message": "Bericht",
        "Date": "Datum",
        "Link": "Link",
        "Location": "Locatie",
        "Description": "Beschrijving",
        "Registration Code": "Registratiecode",
        "Enter your name here...": "Vul hier jouw naam in...",
        "Enter your email here...": "Vul hier jouw email in...",
        "Enter your organisation here...": "Vul hier jouw organisatie in...",
        "Enter event name here...": "Vul hier de naam van je event in...",
        "Enter the event date here...": "Vul hier de datum van je event in...",
        "Enter link to the event page...": "Vul hier de link naar je event pagina in...",
        "Enter the location of the event (city)...": "Vul hier de locatie (stad) van je event in...",
        "Describe the event in max 400 characters...": "Beschrijf het event in maximum 400 karakters...",
        "Enter the registration code you received...": "Vul de registratiecode in die je hebt ontvangen...",
        "Let us know what is on your mind": "Zet hier jouw bericht",
        "Inform me about PoolstokBuzz updates via email": "Hou me op de hoogte over PoolstokBuzz updates via email",
        "Send Us Your Message": "Verzend",
        "EventRegistrationInfoBoxOne": "Je kan je eigen P&O op deze pagina registreren.",
        "EventRegistrationInfoBoxTwo": "Vergeet niet om eerst een event registratiecode aan te vraag onderaan de pagina!",
        "Got it!": "Begrepen!",
        "Thank you!": "Bedankt!",
        "Please provide your contact details": "Gelieve jouw contactinformatie in te vullen",
        "We've received your request and will get in touch with you.": "We hebben je aanvraag ontvangen en nemen zo snel mogelijk contact met je op.",
        "Organisation": "Organisatie",
        "Request Code": "Vraag Code Aan",
        "PoolstokBuzz Event Registration": "PoolstokBuzz Event Registratie",
        "Register Event": "Registreer Event",
        "Request event registration code": "Vraag een event registratiecode aan",
        "First time here?": "Eerste keer hier?",
        "Request Event Registration Code": "Aanvraag Event Registratiecode",
        "InvalidRegistrationCode": "De registratiecode is ongeldig. Heb je ze aangevraagd?",
        "Event Registered": "Event Geregistreerd",
        "Your event has been added to our calendar, how cool is that!": "Je event is toegevoegd aan onze kalendar!",
        "Message Sent!": "Berich Verzonden!",
        "Your message has been sent, we are looking forward to get back to you!": "Jouw bericht is verzonden, we kijken er naar uit om contact met je op te nemen",
        "Message Not Sent!": "Bericht Niet Verzonden!",
        "Your message can not be sent at the moment, please try again later!": "Jouw bericht kan momenteel niet worden verzonden, gelieve het later nogmaals te proberen!",
        "First Name": "Voornaam",
        "Enter your first name here...": "Vul hier je voornaam in...",
        "Last Name": "Familienaam",
        "Enter your last name here...": "Vul hier je familienaam in...",
        "Contact Us": "Contact",
        "InvalidEmail": "Gelieve een geldig email adres in te vullen",
        "Highlighted Events": "Uitgelichte Events",
        "InvalidLink": "Gelieve een geldige http link in te vullen",
        "Keep Me Informed": "Hou Me op de Hoogte",
        "Sign Up to Receive Updates": "Schrijf Je in om Updates te Ontvangen",
        "KeepMeInformed": "Door het invullen van je contactgegevens houden we je op de hoogte wanneer we nieuws hebben over PoolstokBuzz.",
        "We value your privacy, read our privacy policy ": "Jouw privacy is belangrijk voor ons, lees onze privacy policy ",
        "Sign Me Up": "Schrijf Me In",
        "Oops...": "Oeps...",
        "We currently can't complete your subscription, please try again later.": "We kunnen je registratie momenteel niet behandelen, gelieve het later opnieuw te proberen.",
        "You've been successfully subscribed for PoolstokBuzz updates!": "Je registratie is succesvol!",
        "Event Already Exists": "Event Bestaad Reeds",
        "Your event has already been added to our calendar": "Dit event is reeds toegevoegd aan onze kalendar",
        "Invalid Registration Code": "Ongeldige Registratiecode",
        "Your event registration code is invalid": "De ingegeven registratiecode is ongeldig",
        "THIS WAS 2019": "DIT WAS 2019",
        "Look back at the most popular articles of the past year in Belgian healthcare.": "Bekijk de meest besproken artikels van het jaar uit de Nederlandstalige P&O Community.",
        "See The Overview": "Bekijk Het Overzicht",
        "Individuals": "Personen",
        "Organisations": "Organisaties",
        "Belgian Healthcare Professionals": "Nederlandstalige P&O Professionals",
        "Belgian Healthcare Organisations": "Nederlandstalige P&O Organisaties",
        "Score": "Score",
        "Bio": "Bio",
        "or": "of",
        "The 2019 top 100 of the Belgian Healthcare Community on Twitter": "De top 100 van de Nederlandstalige P&0 Community op Twitter in 2019",
        "HIGHLIGHTED ARTICLE": "UITGELICHT ARTIKEL",
        "Buzzing in Belgium": "Populair in België",
        "Buzzing Worldwide": "Populair Wereldwijd",
        "DigitalHealthOverview": "Deze pagina geeft je een overzicht van de meest populaire artikels over digitalisering in P&0 van de afgelopen week.",
        "DigitalHealthOverviewTwo": "Je kan kiezen tussen artikels die in België populair en artikels die wereldwijd populair zijn.",
        "Digital Health": "Digital Health"
      }
    }
  },
  fallbackLng: "nl",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;