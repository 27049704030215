import React, { Component, Fragment } from 'react';
import { Grid, List, Label, Segment, Header, Icon, Button, Image, Modal, Responsive, Loader, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import * as BuzzConstants from '../components/BuzzConstants';
import { withTranslation } from 'react-i18next';
import 'whatwg-fetch';

import logo from '../images/hb-flat-icon.png';

class ExtendedInfluencers extends Component {

    defaultInfluencersUrl = BuzzConstants.INFLUENCERS_WEEK;

    state = {
        influencerItems: [],
        begin: 0,
        end: 10,
        nrOfItems: 10,
        total: 20,
        selectedItem: null,
        selectedUserProfile: null,
        influencersUrl: this.defaultInfluencersUrl
    };

    setInfluencersUrl = (window) => {
        if (window === 'Day') {
            this.setState({ influencersUrl: BuzzConstants.INFLUENCERS_WEEK });
            this.setState({ total: 20 });
            this.setState({ begin: 0 });
            this.setState({ end: 10 });
        } else if (window === 'Week') {
            this.setState({ influencersUrl: BuzzConstants.INFLUENCERS_WEEK });
            this.setState({ total: 20 });
            this.setState({ begin: 0 });
            this.setState({ end: 10 });
        } else if (window === 'Month') {
            this.setState({ influencersUrl: BuzzConstants.INFLUENCERS_MONTH });
            this.setState({ total: 20 });
            this.setState({ begin: 0 });
            this.setState({ end: 10 });
        } else if (window === 'Quarter') {
            this.setState({ influencersUrl: BuzzConstants.INFLUENCERS_QUARTER });
            this.setState({ total: 40 });
            this.setState({ begin: 0 });
            this.setState({ end: 10 });
        } else if (window === 'Year') {
            this.setState({ influencersUrl: BuzzConstants.INFLUENCERS_YEAR_EXT });
            this.setState({ total: 50 });
            this.setState({ begin: 0 });
            this.setState({ end: 10 });
        }
    };

    loadInfluencers() {
        this.setState({ influencerItems:  []});
        fetch(this.state.influencersUrl)
            .then(result => result.json())
            .then(influencerItems => this.setState({ influencerItems }));
    }

    loadUserProfile = (selectedUser) => {
        const url = 'https://api.poolstok.buzz/restapi/tweeps/' + selectedUser;
        fetch(url)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
            .then(selectedUserProfile => this.setState({ selectedUserProfile }))
            .catch(error => console.log(error));
    }

    componentDidMount() {
        this.loadInfluencers();
        this._mounted = true;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.influencersUrl !== this.state.influencersUrl) {
            this.loadInfluencers();
        }
        if (this.state.influencerItems &&
            this.state.influencerItems.length > 0 &&
            this.state.selectedItem === null) {
            const selectedItem = this.state.influencerItems[0];
            if (selectedItem !== undefined) {
                this.selectLabel(selectedItem);
                this.loadUserProfile(selectedItem.userId);
            }
        } else if (this.state.influencerItems !== prevState.influencerItems) {
            const selectedItem = this.state.influencerItems[0];
            if (selectedItem !== undefined) {
                this.selectLabel(selectedItem);
                this.loadUserProfile(selectedItem.userId);
            }
        }
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    componentWillReceiveProps(nextProps) {
        this.setInfluencersUrl(nextProps.window);
    }

    next = () => {
        this.setState({
            begin: this.state.begin + this.state.nrOfItems,
            end: this.state.end + this.state.nrOfItems
        });
    }

    previous = () => {
        this.setState({
            begin: this.state.begin - this.state.nrOfItems,
            end: this.state.end - this.state.nrOfItems
        });
    }

    previousButton = () => {
        const { t } = this.props;
        if (this.state.begin !== 0) {
            return <Button icon='left arrow' labelPosition='left' content={t("Previous")} color='teal' onClick={this.previous} size='mini' />;
        } else {
            return <Button icon='left arrow' labelPosition='left' content={t("Previous")} color='teal' onClick={this.previous} size='mini' basic disabled />;
        }
    }

    nextButton = () => {
        const { t } = this.props;
        if (this.state.end !== this.state.total) {
            return <Button icon='right arrow' labelPosition='right' content={t("Next")} color='teal' onClick={this.next} size='mini' />;
        } else {
            return <Button icon='right arrow' labelPosition='right' content={t("Next")} color='teal' onClick={this.next} size='mini' basic disabled />;
        }
    }

    selectLabel = (item) => {
        if (this.state.selectedItem !== item) {
            this.setState({
                selectedItem: item
            });
        }
    }

    labelSelected = (e, { item }) => {
        this.selectLabel(item);
        this.loadUserProfile(item.userId);
    }

    getHashTagHeader = (hashtags) => {
        const { t } = this.props;
        if (hashtags) {
            return <React.Fragment>
                <Header as='h4' dividing>{t("Tweets About")}</Header>
                {hashtags.slice(0, 5).map((item, index) => {
                    return <Label circular key={index}>#{item.split(":")[0]}</Label>;
                })}
            </React.Fragment>
        }
    }

    getLabel = (item, index) => {
        if (item === this.state.selectedItem) {
            return <Label.Group size='large'>
                <Label as='a' image color='teal' item={item} onClick={this.labelSelected}>
                    <img src={item.profileImageUrl} alt='' />
                    {item.ranking}
                    <Label.Detail>{item.userName.substring(0, 34)}</Label.Detail>
                </Label>
                <Label basic>{item.normalizedScore}</Label>
            </Label.Group>;
        } else {
            return <Label.Group size='large'>
                <Label as='a' image item={item} onClick={this.labelSelected}>
                    <img src={item.profileImageUrl} alt='' />
                    {item.ranking}
                    <Label.Detail>{item.userName.substring(0, 34)}</Label.Detail>
                </Label>
                <Label basic>{item.normalizedScore}</Label>
            </Label.Group>;
        }
    }

    getInfluencerInfo = () => {
        const { t } = this.props;
        if (this.state.selectedUserProfile && this.state.selectedUserProfile.name.trim() !== "") {
            return <Segment textAlign='center'>
                <Header textAlign='center' dividing>{this.state.selectedItem.ranking}. {this.state.selectedUserProfile.name}
                </Header>
                <Image size='small' rounded wrapped src={this.state.selectedUserProfile.profileImageUrl} />
                <Header size='small' as='h4' dividing>{t("Twitter Bio")}</Header>
                <p>{this.state.selectedUserProfile.description}</p>
                {this.getHashTagHeader(this.state.selectedUserProfile.favoriteHashTags)}
                <Button size='mini' circular floated='right' color='twitter' icon='twitter' href={'https://twitter.com/' + this.state.selectedUserProfile.screenName} target='_blank' />
                <Button size='mini' circular floated='right' color='teal' icon='home' href={this.state.selectedUserProfile.url} target='_blank' />
                <Divider hidden/>
                <Divider hidden/>
            </Segment>;
        }
    }

    getModalBody = (item) => {
        const { t } = this.props;
        if (this.state.selectedUserProfile != null) {
            return (
                <Fragment>
                    <Modal.Header>{item.ranking}. {item.userName}
                        <Button size='mini' circular floated='right' color='twitter' icon='twitter' href={'https://twitter.com/' + this.state.selectedUserProfile.screenName} target='_blank' />
                        <Button size='mini' circular floated='right' color='teal' icon='home' href={this.state.selectedUserProfile.url} target='_blank' />
                    </Modal.Header>
                    <Modal.Content image>
                        <Image size='small' circular wrapped src={this.state.selectedUserProfile.profileImageUrl} />
                        <Modal.Description>
                            <Segment raised>
                                <Header as='h4' dividing>{t("Twitter Bio")}</Header>
                                <p>{this.state.selectedUserProfile.description}</p>
                                {this.getHashTagHeader(this.state.selectedUserProfile.favoriteHashTags)}
                            </Segment>
                        </Modal.Description>
                    </Modal.Content>
                </Fragment>
            )
        }
    }

    getInfluencers = () => {
        if (this.state.influencerItems && this.state.influencerItems.length > 0) {
            return <List>
                {this.state.influencerItems.slice(this.state.begin, this.state.end).map((item, index) =>
                    <List.Item key={index}>
                        {this.getLabel(item, index)}
                    </List.Item>
                )}
            </List>;
        } else {
            return <Loader active inline='centered' />;
        }
    }

    getMobileInfluencers = () => {
        if (this.state.influencerItems && this.state.influencerItems.length > 0) {
            return <List>
                {this.state.influencerItems.slice(this.state.begin, this.state.end).map((item, index) =>
                    <List.Item key={index}>
                        <Modal color='teal' size='small' trigger={this.getLabel(item, index)}>
                            {this.getModalBody(item)}
                        </Modal>
                    </List.Item>
                )}
            </List>;
        } else {
            return <Loader active inline='centered' />;
        }
    }

    getInfluencerList = () => {
        const { t } = this.props;
        return <Segment textAlign='center' attached>
            <Header textAlign='center' dividing><Icon name='twitter' color='teal' />
                {t("Top Influencers")}
            </Header>
            <Modal color='teal' size='small' trigger={<Label as='a' corner='right' size='tiny' icon='question' />} closeIcon>
                <Modal.Header>{t("Influencer Ranking")}</Modal.Header>
                <Modal.Content image>
                    <Image wrapped size='medium' src={logo} />
                    <Modal.Description>
                        <p>{t("InfluencerRankingOne")}</p>
                        <p>{t("InfluencerRankingTwo")}</p>
                        <p>{t("InfluencerRankingThree")}</p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <p>{t("Click")} <Label as={Link} to="/rankings" circular color='teal'>{t("here")}</Label> {t("HashtagRankingFour")} </p>
                </Modal.Actions>
            </Modal>
            {this.getInfluencers()}
        </Segment>;
    }

    getMobileInfluencerList = () => {
        const { t } = this.props;
        return <Segment textAlign='center' attached>
            <Header textAlign='center' dividing><Icon name='twitter' color='teal' />
                {t("Top Influencers")}
            </Header>
            <Modal color='teal' size='small' trigger={<Label as='a' corner='right' size='tiny' icon='question' />} closeIcon>
                <Modal.Header>{t("Influencer Ranking")}</Modal.Header>
                <Modal.Content image>
                    <Image wrapped size='medium' src={logo} />
                    <Modal.Description>
                        <p>{t("InfluencerRankingOne")}</p>
                        <p>{t("InfluencerRankingTwo")}</p>
                        <p>{t("InfluencerRankingThree")}</p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <p>{t("Click")} <Label as={Link} to="/rankings" circular color='teal'>{t("here")}</Label> {t("HashtagRankingFour")} </p>
                </Modal.Actions>
            </Modal>
            {this.getMobileInfluencers()}
        </Segment>;
    }

    render() {
        return (<Fragment>
            <Responsive minWidth={1025}>
                <Grid columns={16} padded='horizontally'>
                    <Grid.Column width={1}>
                    </Grid.Column>
                    <Grid.Column width={5} textAlign='center'>
                        {this.getInfluencerList()}
                        <Button.Group attached='bottom'>
                            {this.previousButton()} {this.nextButton()}
                        </Button.Group>
                    </Grid.Column>
                    <Grid.Column width={1}>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        {this.getInfluencerInfo()}
                    </Grid.Column>
                </Grid>
            </Responsive>
            <Responsive maxWidth={1024}>
                <Grid padded='horizontally'>
                    <Grid.Column textAlign='center'>
                        {this.getMobileInfluencerList()}
                        <Button.Group attached='bottom'>
                            {this.previousButton()} {this.nextButton()}
                        </Button.Group>
                    </Grid.Column>
                </Grid>
            </Responsive>
        </Fragment>);
    }
}

export default withTranslation()(ExtendedInfluencers);